import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import { getMarginRateUrl, updateMarginRateUrl } from "../../../library/URLs";
import {UserProfileData} from "../../../library/constants";
import styled from "styled-components";
import { BsPencil } from "react-icons/bs";

const SubmitButton = styled.button`
  width: 250px;
`;

const SteppedNumber = styled.div`
  border-radius: 50%;
  height: 30px;
  min-width: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ee2463;
  cursor: pointer;

  svg {
    font-size: 14px;
  }
`;

const PriceMargins = ({ setErrorVisibility, setSuccessMessage, setSuccessVisibility, setErrorMessage }) => {
    
    const [isPending, setIsPending] = useState(false);

    const [priceForm, setPriceForm] = useState({
        premium: "",
        standard: "",
        economy: "",
        savers: "",
        priority: ""
    });

    const handleInput = (e) => {
        const { name, value } = e.target;

        setPriceForm({
            ...priceForm,
            [name]: value
        });
    }


    const fetchMargins = async () => {
      axios
          .post(getMarginRateUrl, UserProfileData())
          .then(function (response) {
              if (response["data"]["success"] === true) {
                  setPriceForm({
                    ...priceForm,
                    premium: response["data"]["data"]["premium"],
                    economy: response["data"]["data"]["economy"],
                    standard: response["data"]["data"]["standard"],
                    savers: response["data"]["data"]["savers"],
                    priority: response["data"]["data"]["priority"]
                  });
              }
          })
          .catch(function (error) {
              return error;
          });
    }

    const updatePriceMargin = async () => {
        const data = {
            ...UserProfileData(),
            ...priceForm
        }
        setIsPending(true);

        axios
            .post(updateMarginRateUrl, data)
            .then(function (response) {
                setIsPending(false);
                if (response["data"]["success"] === true) {
                    window.scrollTo(0, 0);
                    setSuccessVisibility(true);
                    setSuccessMessage(
                        "Price margin has been updated successfully."
                    );

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                } else {
                    if (response["data"]["success"] === false) {
                        window.scrollTo(0, 0);
                        setErrorVisibility(true);
                        setErrorMessage(response["data"]["message"]);
    
                        setTimeout(() => {
                            setErrorVisibility(false);
                        }, 5000);
                    }
                }
            })
            .catch(function (error) {
                return error;
            });
      }

    useEffect(() => {
        fetchMargins();
    }, [])

    return(
        <>
            
            <p style={{fontSize:22, marginTop:30, marginBottom:0, fontWeight:"bold"}}>Under 3KG Margin Settings</p>
            <span>Set price rates margin for shipment weights below 3KG.</span>
            <div className="row mt-4">
            <form>
                <div className="row">
                    <div className="col-xl-8 col-md-6 col-sm-12 col">
                        <Row className="mb-3 gy-3" >

                            <Col className="form-group col-xl-6 col-md-3">
                                <label htmlFor="Value">Express Premium</label>
                                <div className="d-flex align-items-center gap-2" >
                                    <input name="premium" value={priceForm?.premium} onChange={handleInput} className="form-control" type="text" placeholder="Enter premium margin" />
                                </div>
                            </Col>
                            <Col className="form-group col-xl-6 col-md-6">
                                <label htmlFor="Value">Express Economy</label>
                                <div className="d-flex align-items-center gap-2" >
                                    <input name="economy" value={priceForm?.economy} onChange={handleInput} className="form-control" type="text" placeholder="Enter Economy margin" />
                                </div>
                            </Col>
                            <Col className="form-group col-xl-6 col-md-6">
                                <label htmlFor="Value">Express Priority</label>
                                <div className="d-flex align-items-center gap-2" >
                                    <input name="priority" value={priceForm?.priority} onChange={handleInput} className="form-control" type="text" placeholder="Enter priority margin" />
                                </div>
                            </Col>
                            <Col className="form-group col-xl-6 col-md-6">
                                <label htmlFor="Value">Express Standard</label>
                                <div className="d-flex align-items-center gap-2" >
                                    <input name="standard" value={priceForm?.standard} onChange={handleInput} className="form-control" type="text" placeholder="Enter standard margin" />
                                </div>
                            </Col>
                            <Col className="form-group col-xl-6 col-md-6">
                                <label htmlFor="Value">Express Savers</label>
                                <div className="d-flex align-items-center gap-2" >
                                    <input name="savers" value={priceForm?.savers} onChange={handleInput} className="form-control" type="text" placeholder="Enter savers margin" />
                                </div>
                            </Col>
                        </Row>

                    </div>

                    <div className="d-flex flex-row my-5 justify-content-start">
                        <SubmitButton type="button" className="confirm-button" onClick={updatePriceMargin}>
                            {isPending === false ? (
                                <span className="button-text">UPDATE MARGIN</span>
                            ) : (
                                <span className="spinner-border spinner-grow-sm"/>
                            )}
                        </SubmitButton>
                    </div>
                </div>
            </form>
            </div>
        </>
    )
}

export default PriceMargins;