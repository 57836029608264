import * as React from "react";
import {useEffect, useState} from "react";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {
    getCustomerReferralDetailsURL,
    getCustomerReferredUsersURL,
    getCustomerWalletDetailsURL
} from "../../../library/URLs";
import styled from "styled-components";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomerReferrals = ({customerID, referralCode}) => {
    const navigate = useNavigate();

    const [accountBalance, setAccountBalance] = useState("");
    const [totalFunded, setTotalFunded] = useState("");
    const [totalSpent, setTotalSpent] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [referredUsers, setReferredUsers] = useState([]);

    const data = {
        customer_id: customerID,
        limit: 20,
        offset: 0,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getCustomerReferralDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response["data"]["success"] === false) return;
                const accountBalance = response["data"]["data"]["account_balance"];
                const totalFunded = response["data"]["data"]["total_funded"];
                const totalSpent = response["data"]["data"]["total_spent"];

                setAccountBalance(accountBalance);
                setTotalFunded(totalFunded);
                setTotalSpent(totalSpent);
            });
    }, []);

    useEffect(() => {
        axios
            .post(getCustomerReferredUsersURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const referredUsers = response["data"]["data"];

                if (referredUsers.length === 0)
                    setIsEmpty(true);

                setReferredUsers(referredUsers);
            });
    }, []);

    const handleClick = (referralID) => {
        navigate("/customer/" + referralID);
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    return (
        <>
            <div className="card bg-light border-0 shadow">
                <div className="card-body">
                    <div className="d-flex flex-row justify-content-between">
                        <span className="header">Referral Details</span>
                    </div>

                    <div className="customer-overview-container pt-3">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column align-items-center pt-4" style={{border:"1px dashed #AAB7B8",
                                    padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                    <span style={{fontWeight:"bold"}}>{accountBalance === "" ? "..." : parseFloat(accountBalance).toLocaleString("en")}</span>
                                    <span>Number Of Referrals</span>
                                </div>

                                <div className="d-flex flex-column align-items-center pt-4" style={{border:"1px dashed #AAB7B8",
                                    padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                    <span style={{fontWeight:"bold"}}>₦{totalFunded === "" ? "..." : parseFloat(totalFunded).toLocaleString("en")}</span>
                                    <span>Amount Generated</span>
                                </div>
                            </div>

                            <div>
                                <p style={{margin:0}}>Referral Link</p>
                                <span>This customer's code can be used for during sign ups to get referrals</span>
                                <div className="d-flex flex-row justify-content-between mt-3">
                                    <div style={{backgroundColor:"#EAF2F8", padding: "10px 8px", borderRadius: "6px", width:"50%"}}>
                                        {referralCode}
                                    </div>

                                    <input className="confirm-button" type="button" onClick={(e)=> copyToClipboard(referralCode)} value="COPY CODE" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card bg-light border-0 shadow mt-4">
                <div className="card-body">
                    <span className="header">Referred Users</span>

                    {isLoading === false ? null : (
                        <LoadingContainer>
                            <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                        </LoadingContainer>
                    )}

                    {isEmpty === false ?
                        <div className="shipments-table-container">
                            <table  className="shipments-table">
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Phone </th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Registration Date</th>
                                    <th scope="col">Number Of Shipments</th>
                                </tr>
                                </thead>
                                {isLoading === true ? null : (
                                    <tbody>
                                    {referredUsers.map((user) => {
                                        const lastActivityTime = moment(user.date_time);
                                        const timestamp = lastActivityTime.format('ddd MMM Do, h:mm A');

                                        return (
                                            <tr style={{fontSize:"14.4px"}} key={user.id} onClick={() => handleClick(user.id)}>
                                                <td>{user.fullname}</td>
                                                <td>{user.phone}</td>
                                                <td>{user.email}</td>
                                                <td>{timestamp}</td>
                                                <td>{timestamp}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        :
                        <EmptyContainer>
                            <p>Oops... Sorry, customer has no referrals</p>
                        </EmptyContainer>
                    }
                </div>
            </div>
        </>
    );
}

export default CustomerReferrals;