import {Col, Modal, Row} from "react-bootstrap";
import axios from "axios";
import {
    approveShipmentURL,
    getStationsURL,
    makeRegularUserURL,
    uploadImageURL,
    uploadWaybillURL
} from "../library/URLs";
import {useEffect, useRef, useState} from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const ApproveImportShipmentDialog = ({ isModalVisible, handleCloseDialog, shipmentID, manualApprove }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [fedexEconomy, setFedexEconomy] = useState(false);
    const [fedexPriority, setFedexPriority] = useState(false);
    const [dhl, setDHL] = useState(false);
    const [aramex, setAramex] = useState(false);
    const [ups, setUPS] = useState(false);
    const [anka, setAnka] = useState(false);

    const [stations, setStations] = useState([]);

    const [shipmentProcessor, setShipmentProcessor] = useState("");

    const [processViaAPI, setProcessViaAPI] = useState("Yes");
    const [trackingNumber, setTrackingNumber] = useState("");
    const [waybillUrl, setWaybillURL] = useState("");
    const [waybillFile, setWaybillFile] = useState();
    const [waybillLoading, setWaybillLoading] = useState(false);

    const [dropoffLocation, setDropoffLocation] = useState("");

    const toggleShipmentTypeCheckBox = (shipmentType) => {
        if (shipmentType === "fedexEconomy"){
            setFedexEconomy(true);
            setFedexPriority(false);
            setDHL(false);
            setUPS(false);
            setAnka(false);
            setAramex(false);
            setShipmentProcessor("Fedex-Economy");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "fedexPriority"){
            setFedexEconomy(false);
            setFedexPriority(true);
            setDHL(false);
            setUPS(false);
            setAnka(false);
            setAramex(false);
            setShipmentProcessor("Fedex-Priority");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "dhl"){
            setFedexEconomy(false);
            setFedexPriority(false);
            setDHL(true);
            setUPS(false);
            setAramex(false);
            setAnka(false);
            setShipmentProcessor("DHL");
            setProcessViaAPI("Yes")
        } else if (shipmentType === "ups"){
            setFedexEconomy(false);
            setFedexPriority(false);
            setDHL(false);
            setUPS(true);
            setAnka(false);
            setAramex(false);
            setShipmentProcessor("UPS");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "aramex"){
            setFedexEconomy(false);
            setFedexPriority(false);
            setDHL(false);
            setUPS(false);
            setAramex(true);
            setAnka(false);
            setShipmentProcessor("Aramex");
            setProcessViaAPI("Yes");
        } else if (shipmentType === "anka"){
            setFedexEconomy(false);
            setFedexPriority(false);
            setDHL(false);
            setUPS(false);
            setAramex(false);
            setAnka(true);
            setShipmentProcessor("Anka");
            setProcessViaAPI("Yes");
        }
    }

    const uploadWaybillDocument = (e) => {
        // const fileInput = document.querySelector('#waybill-document');
        const {files} = e.target;

        const formValues = {};
        formValues["image"] = files[0];

        setWaybillLoading(true);

        axios
            .post(uploadWaybillURL, formValues, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                const waybillURL = response["data"];

                setWaybillURL(waybillURL);
                setWaybillLoading(false);
            })
            .catch(error => console.log(error));
    }

    useEffect(() => {
        axios
            .post(getStationsURL, UserProfileData())
            .then(function (response) {
                if (response["data"]["data"]["success"] === true) {
                    const stations = response["data"]["data"]["data"];

                    setStations(stations);
                }
            })
            .catch(function (error) {
                return error;
            });
    }, [])

    const onDropoffLocationChange = (e) => {
        setDropoffLocation(e.target.value);
    }

    const approveShipment = (waybillURL) => {
        if(waybillLoading) {
            setErrorMessage("Waybill document is uploading!");
            setErrorVisibility(true);
            window.scrollTo(0, 0);

            setTimeout(() => {
                setErrorVisibility(false);
            }, 3000);
            return;
        }
        
        if(manualApprove) {
            if(trackingNumber === "" || waybillUrl === "") {
                setErrorMessage("Ensure to provide tracking number and waybill when approving manually!");
                setErrorVisibility(true);
                window.scrollTo(0, 0);
    
                setTimeout(() => {
                    setErrorVisibility(false);
                }, 5000);
                return;
            } 
        }

        const formValues = {};
        formValues['shipment_id'] = shipmentID;
        formValues['shipment_type'] = "International";
        formValues['shipment_processor'] = shipmentProcessor;
        formValues['dropoff_location'] = dropoffLocation;
        formValues['via_processor_api'] = manualApprove ? "No" : "Yes";
        
        if(manualApprove) {
            formValues['tracking_number'] = trackingNumber;
            formValues['waybill_url'] = waybillUrl;
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(approveShipmentURL, newData)
            .then(function (response) {
                setIsSubmitting(false);
                console.log(response);

                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Shipment has now been approved for processing");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog("Refresh");
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                console.log(error);
                return error;
            });
    };

    const onTrackingNumberChanged = (e) => {
        setTrackingNumber(e.target.value)
    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
        <Modal.Header>
            <h5 className="header">APPROVE IMPORT SHIPMENT</h5>
            <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
        </Modal.Header>
        <Modal.Body>
            {
                isError === false ? null :
                    (<ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>)
            }

            {
                isSuccess=== false ? null :
                    (<SuccessMessageContainer>
                        {successMessage}
                    </SuccessMessageContainer>)
            }

            <p>You are about to approve this shipment</p>
            <p>This means the shipment would be processed for delivery.</p>
            <p>How would you like to continue?</p>

            <div>
                <input name="shipment_processor" checked={dhl} onChange={(e) => toggleShipmentTypeCheckBox("dhl")}
                       className="form-check-input" type="radio"   />
                <label className="form-check-label" htmlFor="allowedShipment">
                    DHL Express Premium
                </label>
                {
                    dhl === false ? null :
                        <div>
                            <Row className="mt-3" style={{marginLeft:30}}>
                                <Col>
                                    <label htmlFor="Base From">Tracking Number</label>
                                    <input name="tracking_number" className="form-control" type="text" onChange={(e) => onTrackingNumberChanged(e)}  />
                                </Col>
                            </Row>
                            <Row className="mt-3" style={{marginLeft:30, marginBottom:30}}>
                                <Col>
                                    <label htmlFor="Base From">Waybill Document</label>
                                    <input type="file" id="waybill-document" className="form-control-file"  />
                                </Col>
                            </Row>
                        </div>
                }

                <div style={{marginTop:10}}>
                    <input name="shipment_processor" checked={fedexPriority} onChange={(e) => toggleShipmentTypeCheckBox("fedexPriority")}
                           className="form-check-input" type="radio"   />
                    <label className="form-check-label" htmlFor="allowedShipment">
                        Fedex Priority (Express Priority)
                    </label>
                </div>

                <div style={{marginTop:10}}>
                    <input name="shipment_processor" checked={fedexEconomy} onChange={(e) => toggleShipmentTypeCheckBox("fedexEconomy")}
                           className="form-check-input" type="radio"   />
                    <label className="form-check-label" htmlFor="allowedShipment">
                        Fedex Economy (Express Savers)
                    </label>
                </div>

                <div style={{marginTop:10}}>
                    <input name="shipment_processor" checked={aramex} onChange={(e) => toggleShipmentTypeCheckBox("aramex")}
                           className="form-check-input" type="radio"  />
                    <label className="form-check-label" htmlFor="allowedShipment">
                        Aramex
                    </label>
                </div>

                <div style={{marginTop:10}}>
                    <input name="shipment_processor" checked={ups} onChange={(e) => toggleShipmentTypeCheckBox("ups")}
                           className="form-check-input" type="radio"   />
                    <label className="form-check-label" htmlFor="allowedShipment">
                        UPS Express Saver
                    </label>
                </div>

                <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center" >
                    <input name="shipment_processor" checked={anka} onChange={(e) => toggleShipmentTypeCheckBox("anka")}
                        className="form-check-input" type="radio" />
                    <div className="d-flex w-100 justify-content-between" >
                        <label className="form-check-label" htmlFor="allowedShipment">
                            Anka
                        </label>
                    </div>
                </div>

                <Row className="mt-3">
                    <Col>
                        <label htmlFor="Base From">Dropoff Location</label>
                        <select name="dropoff_location" onChange={(e) => onDropoffLocationChange(e)} className="form-select" >
                            <option value="Receiver">Directly To Receiver</option>
                            {stations.map((station) => {
                                return(<option key={station.id} value={station.id}>{station.address}</option>);
                            })}
                        </select>
                    </Col>
                </Row>

                {
                    manualApprove && (
                        <div style={{ marginTop: 10 }} className="d-flex gap-1 align-items-center">
                            <div className="row gap-3 my-3">
                                <div className="col-12">
                                    <label htmlFor="" className="form-check-label">
                                        Enter Tracking Number
                                    </label>
                                    <input 
                                        type="tel" 
                                        name="tracking_number"
                                        value={trackingNumber}
                                        onChange={e => setTrackingNumber(e.target.value)}
                                        id="tracking_number"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="d-flex gap-2 align-items-center">
                                        <label htmlFor="" className="form-check-label">
                                            Upload Waybill Document
                                        </label>
                                        {
                                            waybillLoading && (<span className="spinner-border spinner-grow-sm"/>)
                                        }
                                    </div>
                                    <input 
                                        type="file" 
                                        name="waybill"
                                        value={waybillFile}
                                        onChange={uploadWaybillDocument}
                                        id="waybill"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="cancel-button mx-3" onClick={handleCloseDialog}>
                CANCEL
            </button>
            <SubmitButton onClick={(e)=> approveShipment("")} type="button" className="confirm-button">
                {isSubmitting === false ? (
                    <span className="button-text">APPROVE SHIPMENT</span>
                ) : (
                    <span className="spinner-border spinner-grow-sm"/>
                )}
            </SubmitButton>
            {/* {
                dhl === true ?
                    <SubmitButton onClick={uploadWaybillDocument} type="button" className="confirm-button">
                        {isSubmitting === false ? (
                            <span className="button-text">APPROVE SHIPMENT</span>
                        ) : (
                            <span className="spinner-border spinner-grow-sm"/>
                        )}
                    </SubmitButton>
                    :
                    <SubmitButton onClick={(e)=> approveShipment("")} type="button" className="confirm-button">
                        {isSubmitting === false ? (
                            <span className="button-text">APPROVE SHIPMENT</span>
                        ) : (
                            <span className="spinner-border spinner-grow-sm"/>
                        )}
                    </SubmitButton>
            } */}
        </Modal.Footer>
    </Modal>
  );
};

export default ApproveImportShipmentDialog;
