import * as React from "react";
import {useEffect, useState} from "react";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {
    getCustomerWalletDetailsURL,
    getCustomerWalletHistoryURL
} from "../../../library/URLs";
import styled from "styled-components";
import {GoPrimitiveDot} from "react-icons/go";
import TopupUserWalletDialog from "../../../modals/TopupUserWallet";
import DeductUserWalletDialog from "../../../modals/DeductUserWalletDialog";
import moment from "moment";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomerWallet = ({customerID, customerPhoneNumber, customerName}) => {
    const [isFundUserWalletModalVisible, setIsFundUserWalletModalVisibility] = useState(false);
    const [isDeductUserWalletModalVisible, setIsDeductUserWalletModalVisibility] = useState(false);

    const [accountBalance, setAccountBalance] = useState("");
    const [totalFunded, setTotalFunded] = useState("");
    const [totalSpent, setTotalSpent] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [walletHistory, setShipments] = useState([]);

    const data = {
        customer_id: customerID,
        limit: 20,
        offset: 0,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getCustomerWalletDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (response["data"]["success"] === false) return;
                console.log(response["data"]);
                const accountBalance = response["data"]["data"]["account_balance"];
                const totalFunded = response["data"]["data"]["total_funded"];
                const totalSpent = response["data"]["data"]["total_spent"];

                setAccountBalance(accountBalance);
                setTotalFunded(totalFunded);
                setTotalSpent(totalSpent);
            });
    }, []);

    useEffect(() => {
        axios
            .post(getCustomerWalletHistoryURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const shipments = response["data"]["data"];

                if (shipments.length === 0)
                    setIsEmpty(true);

                setShipments(shipments);
            });
    }, []);

    const editCustomerProfile = () => {

    };

    const handleClick = () => {

    }

    const openTopupUserWalletDialog = () => {
        setIsFundUserWalletModalVisibility(true);
    }

    const closeTopupUserWalletDialog = (refresh) => {
        setIsFundUserWalletModalVisibility(false);
        if (refresh === "Refresh"){
            window.location.reload();
        }
    }

    const openDeductUserWalletDialog = () => {
        setIsDeductUserWalletModalVisibility(true);
    }

    const closeDeductUserWalletDialog = (refresh) => {
       setIsDeductUserWalletModalVisibility(false);
        if (refresh === "Refresh"){
            window.location.reload();
        }
    }

    return (
        <>
            <TopupUserWalletDialog
                customerID={customerID}
                phoneNumber={customerPhoneNumber}
                customerName={customerName}
                isModalVisible={isFundUserWalletModalVisible}
                handleCloseDialog={closeTopupUserWalletDialog}
            />

            <DeductUserWalletDialog
                customerID={customerID}
                phoneNumber={customerPhoneNumber}
                customerName={customerName}
                isModalVisible={isDeductUserWalletModalVisible}
                handleCloseDialog={closeDeductUserWalletDialog}
            />

            <div className="card bg-light border-0 shadow">
                <div className="card-body">
                    <div className="d-flex flex-row justify-content-between">
                        <span className="header">Wallet Details</span>
                        <div>
                            <input style={{marginRight:20}} className="cancel-button" type="button" onClick={(e)=> openDeductUserWalletDialog()} value="DEDUCT WALLET" />
                            <input className="confirm-button" type="button" onClick={(e)=> openTopupUserWalletDialog()} value="TOPUP WALLET" />
                        </div>
                    </div>

                    <div className="customer-overview-container pt-3">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row mb-4">
                                <div className="d-flex flex-column align-items-center" style={{border:"1px dashed #AAB7B8",
                                    padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                    <span style={{fontWeight:"bold"}}>₦{accountBalance === "" ? "..." : parseFloat(accountBalance).toLocaleString("en")}</span>
                                    <span>Balance</span>
                                </div>

                                <div className="d-flex flex-column align-items-center" style={{border:"1px dashed #AAB7B8",
                                    padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                    <span style={{fontWeight:"bold"}}>₦{totalFunded === "" ? "..." : parseFloat(totalFunded).toLocaleString("en")}</span>
                                    <span>Total Funded</span>
                                </div>

                                <div className="d-flex flex-column align-items-center" style={{border:"1px dashed #AAB7B8",
                                    padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                    <span style={{fontWeight:"bold"}}>₦{totalSpent === "" ? "..." : parseFloat(totalSpent).toLocaleString("en")}</span>
                                    <span>Total Spent</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card bg-light border-0 shadow mt-4">
                <div className="card-body">
                    <span className="header">Wallet History</span>

                    {isLoading === false ? null : (
                        <LoadingContainer>
                            <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                        </LoadingContainer>
                    )}

                    {isEmpty === false ?
                        <div className="shipments-table-container">
                            <table  className="shipments-table">
                                <thead>
                                <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Date</th>
                                </tr>
                                </thead>
                                {isLoading === true ? null : (
                                    <tbody>
                                    {walletHistory.map((history) => {
                                        const dateTime = moment(history.date_time);
                                        const timestamp = dateTime.format('ddd MMM Do, h:mm A');
                                        const color = history.type === "credit" ? "green" : "red";
                                        return (
                                            <tr style={{fontSize:"14.4px"}} key={history.id} onClick={() => handleClick(history.id)}>
                                                <td>{history.description}</td>
                                                <td>₦{parseFloat(history.amount).toLocaleString("en")}</td>
                                                <td><GoPrimitiveDot style={{color:color}} />{history.type}</td>
                                                <td>{timestamp}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        :
                        <EmptyContainer>
                            <p>Oops... Sorry, customer has no wallet history</p>
                        </EmptyContainer>
                    }
                </div>
            </div>
        </>
    );
}

export default CustomerWallet;