import styles from "../styles/components.scss";
import cn from "classnames";
import styled from "styled-components";

const ErrorNotificationWrapper = styled.div`
  background-color: #fadbd8;
  border-radius: 3px;
  width: 500px;
  text-align: left;
  color: #b03a2e;
  position: absolute;
  padding: 5px 10px 5px 10px;
  top: 20px;
  right: 0;
`;

const SuccessNotificationWrapper = styled.div`
  background-color: #196f3d;
  border-radius: 3px;
  width: 500px;
  text-align: left;
  color: #ffffff;
  position: absolute;
  padding: 5px 10px 5px 10px;
  top: 20px;
  right: 0;
`;

export const ErrorNotification = ({ message }) => {
  return (
    <ErrorNotificationWrapper>
      <span>{message}</span>
    </ErrorNotificationWrapper>
  );
};

export const SuccessNotification = ({ message }) => {
  return (
    <SuccessNotificationWrapper>
      <p style={{marginBottom:0, fontWeight: "bold"}}>Success</p>
      <span>{message}</span>
    </SuccessNotificationWrapper>
  );
};

export const Colors = {
  success: "success",
  error: "error",
};
