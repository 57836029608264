import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { UserProfileData } from "../../library/constants";
import { getTransactionsURL } from "../../library/URLs";

import * as React from "react";
import airtime from "../../images/airtime.jpg";
import electricity from "../../images/electricity.webp"
import accountFunding from "../../images/account-funding.png"
import styled from "styled-components";
import moment from "moment";

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RecentTransactions = () => {
  const [isRetrieving, setIsRetrieving] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState([]);

  const data = {
    limit: 6,
    offset: 0,
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getTransactionsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsRetrieving(false);

        if (response["data"]["success"] === false) return;

        const transactions = response["data"]["data"];

        if (transactions.length === 0)
          setIsEmpty(true);

        setRecentTransactions(transactions);
      });
  }, []);

  return (
    <div className="col-xl-6 col-md-12">
      <div className="card bg-light border-0 shadow">
        <div className="card-body recent-transactions-container">
          <div className="d-flex justify-content-between mb-2">
            <h6>Recent Transactions</h6>
            <span className="small">See More</span>
          </div>
          <div className="recent-transactions-body">
            {isRetrieving === true ?
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                </LoadingContainer> : isEmpty === true ?
                <EmptyContainer>
                  <p>Oops... Sorry, you have no recorded transactions</p>
                </EmptyContainer> :
                <table className="recent-transactions-table">
                  <tbody>
                    {recentTransactions.map((transaction) => {
                      const dateTime = moment(transaction.date_time);
                      const timestamp = dateTime.format('ddd MMM Do');

                      let description;
                      let imageIcon;
                      if (transaction.type === "Transfer"){
                        description = "Transfer to " + transaction.account_name;
                        imageIcon = airtime;
                      } else if (transaction.type === "Airtime"){
                        description = "Airtime purchase to " + transaction.phone_number;
                        imageIcon = airtime;
                      } else if (transaction.type === "Account Funding" || transaction.type === "Shipment Payment"){
                        description = transaction.type ;
                        imageIcon = accountFunding;
                      } else if (transaction.type === "Electricity"){
                        description = "Electricity purchase to " + transaction.account_number;
                        imageIcon = electricity;
                      }
                      return (
                          <tr key={transaction.id}>
                            <td className="description">
                              <div className="d-flex align-items-center">
                                <img className="icon" src={imageIcon} alt="" />
                                <div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span style={{fontSize:15.5}}>{description}</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="amount">₦{parseFloat(transaction.amount).toLocaleString("en")}</td>
                            <td className="status">{timestamp}</td>
                          </tr>
                      );
                    })}
                  </tbody>
                </table>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentTransactions;
