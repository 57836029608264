import "../styles/home.scss";
import "../styles/single-partner.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {HiOutlineDotsVertical} from "react-icons/hi";
import styled from "styled-components";
import * as React from "react";
import TopupUserWalletDialog from "../modals/TopupUserWallet";
import DeductUserWalletDialog from "../modals/DeductUserWalletDialog";
import {get520PartnerDetailsURL} from "../library/Five20URLs";
import PartnerActivities from "./Widgets/Partner/PartnerTransactions";
import ActivatePartnerDialog from "../modals/ActivatePartnerDialog";
import DeactivatePartnerDialog from "../modals/DeactivatePartnerDialog";
import {BsDot} from "react-icons/bs";
import SubscriptionInactive from "../components/SubscriptionInactive";


const ProfileImage = styled.img`
    border-radius:50%;
    width:65px;
    height:65px;    
`;

const CommunicationContainer = styled.div`
    padding:4px 8px;
    border-radius:5px;
    background-color:#E5E7E9;
    color:#2E4053;
    font-size:12px;
    margin-right:3px;
    margin-left:3px;
    cursor:pointer
`;

const SinglePartner = () => {
    const params = useParams();
    const partnerID = params["partnerID"];

    const [isFundUserWalletModalVisible, setIsFundUserWalletModalVisibility] = useState(false);
    const [isDeductUserWalletModalVisible, setIsDeductUserWalletModalVisibility] = useState(false);
    const [isDeactivatePartnerVisible, setDeactivatePartnerVisibility] = useState(false);
    const [isActivatePartnerVisible, setActivatePartnerVisibility] = useState(false);

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [memberType, setMemberType] = useState("");
    const [activationStatus, setActivationStatus] = useState("");
    const [confirmationStatus, setConfirmationStatus] = useState("");
    const [confirmationStatusClass, setConfirmationStatusClass] = useState("");
    const [profileImageURL, setProfileImageURL] = useState("https://users.mylogisticsapp.com/engine/v1/actions/profiles/default-profile-image.png");
    const [creationTimestamp, setCreationTimestamp] = useState("");
    const [bvn, setBVN] = useState("");
    const [identityURL, setIdentityURL] = useState("");


    const[userActionMenuVisibility, setUserActionMenuVisibility] = useState(false);

    const toggleFundUserWalletDialog = (type) => {
        type === "open" ? setIsFundUserWalletModalVisibility(true) : setIsFundUserWalletModalVisibility(false);
        setUserActionMenuVisibility(false);
    }

    const toggleDeductUserWalletDialog = (type) => {
        type === "open" ? setIsDeductUserWalletModalVisibility(true) : setIsDeductUserWalletModalVisibility(false);
        setUserActionMenuVisibility(false);
    }

    const toggleUserActionMenu = () => {
        setUserActionMenuVisibility(!userActionMenuVisibility);
    }

    const toggleDeactivationStatusDialog = () => {
        setUserActionMenuVisibility(!userActionMenuVisibility);
        setDeactivatePartnerVisibility(!isDeactivatePartnerVisible);
    };

    const toggleActivateStatusDialog = () => {
        setUserActionMenuVisibility(!userActionMenuVisibility);
        setActivatePartnerVisibility(!isActivatePartnerVisible);
    }

    const handleCloseModal = (memberType) => {
        if (memberType === "Contract"){
            setMemberType("Contract");
        } else if (memberType === "Regular"){
            setMemberType("Regular");
        }
        setDeactivatePartnerVisibility(false);
        setActivatePartnerVisibility(false);
    };

    const data = {
        partner_id: partnerID,
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(get520PartnerDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(get520PartnerDetailsURL);
                if (response["data"]["success"] === false) return;

                const shipmentDetails = response["data"];

                const firstName = shipmentDetails["data"]["first_name"];
                const lastName = shipmentDetails["data"]["last_name"];
                const phoneNumber = shipmentDetails["data"]["phone"];
                const email = shipmentDetails["data"]["email"];
                const memberType = shipmentDetails["data"]["member_type"];
                const memberStatus = shipmentDetails["data"]["activation_status"];
                const confirmationStatus = shipmentDetails["data"]["confirmation_status"];
                const profileImageURL = shipmentDetails["data"]["profile_image_url"];
                const bvn = shipmentDetails["data"]["bvn"];
                const identityURL = shipmentDetails["data"]["identity_url"];

                const confirmationStatusClass = confirmationStatus === "Yes" ? "Activated" : "NotActivated";

                const creationTimestamp = new Date(shipmentDetails["data"]["date_created"]).toDateString();

                setName(firstName + " " + lastName);
                setPhoneNumber(phoneNumber);
                setEmail(email);
                setMemberType(memberType);
                setActivationStatus(memberStatus);
                setConfirmationStatus(confirmationStatus);
                setProfileImageURL(profileImageURL);
                setCreationTimestamp(creationTimestamp);
                setBVN(bvn);
                setIdentityURL(identityURL);
                setConfirmationStatusClass(confirmationStatusClass);
            });
    }, []);

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Partner Details</h5>
                        <span>Home</span>
                    </div>

                    <TopupUserWalletDialog
                        customerID={partnerID}
                        phoneNumber={phoneNumber}
                        customerName={name}
                        isModalVisible={isFundUserWalletModalVisible}
                        handleCloseDialog={(e) => toggleFundUserWalletDialog("close")}
                    />

                    <DeductUserWalletDialog
                        customerID={partnerID}
                        phoneNumber={phoneNumber}
                        customerName={name}
                        isModalVisible={isDeductUserWalletModalVisible}
                        handleCloseDialog={(e) => toggleDeductUserWalletDialog("close")}
                    />

                    <DeactivatePartnerDialog
                        isModalVisible={isDeactivatePartnerVisible}
                        handleCloseDialog={handleCloseModal}
                        partnerID={partnerID}
                        getContractDetails={true}
                    />

                    <ActivatePartnerDialog
                        isModalVisible={isActivatePartnerVisible}
                        handleCloseDialog={handleCloseModal}
                        partnerID={partnerID}
                    />

                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-xl-4 col-md-12">
                                <div className="card bg-light border-0 shadow">
                                    <div className="card-body shipper-container">
                                        <div className="d-flex flex-column align-items-center">
                                            <div style={{width:"100%"}} className="d-flex justify-content-end">
                                                <div className="user-menu-container">
                                                    <HiOutlineDotsVertical onClick={toggleUserActionMenu} style={{width:25, cursor:"pointer"}} className="icon"/>
                                                    <div className={`user-menu ${userActionMenuVisibility ? "user-menu-visible" : null}`}>
                                                        {confirmationStatus === "Yes" ?
                                                            (<span onClick={toggleDeactivationStatusDialog}>Deactivate Partner</span>) :
                                                            (<span onClick={toggleActivateStatusDialog}>Activate Partner</span>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <ProfileImage src={profileImageURL} />
                                            <div style={{textAlign: 'center'}}>
                                                <span className="title">{name}</span>
                                                <div className="d-flex flex-row align-items-center">
                                                    <span>{memberType}</span>
                                                    <BsDot/>
                                                    <span className={confirmationStatusClass}>{confirmationStatus === "Yes" ? "Activated" : "Not Activated"}</span>
                                                </div>
                                            </div>
                                            {/*<div className="d-flex justify-content-center">*/}
                                            {/*    <CommunicationContainer>*/}
                                            {/*        Send SMS*/}
                                            {/*    </CommunicationContainer>*/}
                                            {/*    <CommunicationContainer>*/}
                                            {/*        Send Notification*/}
                                            {/*    </CommunicationContainer>*/}
                                            {/*    <CommunicationContainer>*/}
                                            {/*        Send Email*/}
                                            {/*    </CommunicationContainer>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className="mt-4">
                                            <span className="title">Email</span>
                                            <p>{email}</p>
                                        </div>
                                        <div className="mt-4">
                                            <span className="title">Phone Number</span>
                                            <p>{phoneNumber}</p>
                                        </div>
                                        <div className="mt-4">
                                            <span className="title">Creation Date</span>
                                            <p>{creationTimestamp}</p>
                                        </div>
                                        <div className="mt-4">
                                            <span className="title">BVN</span>
                                            <p>{bvn}</p>
                                        </div>
                                        <div className="mt-4">
                                            <span className="title">Identity Card</span>
                                            <p>
                                                Click{" "}
                                                <a style={{textDecoration:"none"}} target="_blank" href={identityURL}>
                                                    here
                                                </a>{" "}
                                                to view identity card
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-8 col-md-12">
                                <PartnerActivities partnerID={partnerID}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default SinglePartner;
