import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {getShipmentsByUserURL, getShipmentsURL} from "../../../library/URLs";
import styled from "styled-components";
import CustomerOverview from "../Customer/CustomerOverview";
import {get520TransactionsURL} from "../../../library/Five20URLs";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartnerActivities = ({partnerID}) => {
    const navigator = useNavigate();

    const [activeTab, setActiveTab] = useState("PartnerTransactions");

    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [transactions, setTransactions] = useState([]);

    const [accountBalance, setAccountBalance] = useState("");
    const [totalFunded, setTotalFunded] = useState("");
    const [totalSpent, setTotalSpent] = useState("");

    const data = {
        partner_id: partnerID,
        limit: 20,
        offset: 0,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };
    console.log(newData);

    useEffect(() => {
        axios
            .post(get520TransactionsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const shipments = response["data"]["data"];

                console.log(shipments.length);

                if (shipments.length === 0)
                    setIsEmpty(true);

                setTransactions(shipments);
            });
    }, []);

    const handleClick = (shipmentID) => {
        navigator("/shipment/" + shipmentID);
    };

    return (
        <div className="card bg-light border-0 shadow">
            <div className="card-body">
                <div>
                    <span onClick={(e)=> setActiveTab("PartnerTransactions")} className={`profile-tab ${activeTab === "PartnerTransactions" ? "active-profile" : null}`}>Transactions</span>
                    <span onClick={(e)=> setActiveTab("PartnerWallet")} className={`profile-tab ${activeTab === "PartnerWallet" ? "active-profile" : null}`}>Wallet</span>
                </div>

                <div className={`shipments-table-container ${activeTab === "PartnerTransactions" ? "show-customer-tab" : "hide-customer-tab"}`}>
                    {
                        isLoading === true ? null :
                            isEmpty === true ?
                                <EmptyContainer>
                                    <p>Oops... Sorry, admin has no activity</p>
                                </EmptyContainer> :
                                <table  className="shipments-table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Sender</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                    </thead>
                                    {isLoading === true ? null :
                                        <tbody>
                                        {transactions.map((shipment) => {
                                            const timestamp = new Date(
                                                shipment.date_time
                                            ).toDateString();

                                            return (
                                                <tr style={{fontSize:"14.4px"}} key={shipment.id} onClick={() => handleClick(shipment.id)}>
                                                    <td>{shipment.recipient_name}</td>
                                                    <td>{shipment.drop_off_location}</td>
                                                    <td>
                                                        ₦
                                                        {parseFloat(shipment.total_amount).toLocaleString(
                                                            "en"
                                                        )}
                                                    </td>
                                                    <td>{timestamp}</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    }
                                </table>
                    }

                </div>

                <div className={`${activeTab === "PartnerWallet" ? "show-customer-tab" : "hide-customer-tab"}`}>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="header">Wallet Details</span>
                        <div>
                            <input style={{marginRight:20}} className="cancel-button" type="button" value="DEDUCT WALLET" />
                            <input className="confirm-button" type="button" value="TOPUP WALLET" />
                        </div>
                    </div>

                    <div className="customer-overview-container pt-3">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row mb-4">
                                <div className="d-flex flex-column align-items-center" style={{border:"1px dashed #AAB7B8",
                                    padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                    <span style={{fontWeight:"bold"}}>₦{accountBalance === "" ? "..." : parseFloat(accountBalance).toLocaleString("en")}</span>
                                    <span>Balance</span>
                                </div>

                                <div className="d-flex flex-column align-items-center" style={{border:"1px dashed #AAB7B8",
                                    padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                    <span style={{fontWeight:"bold"}}>₦{totalFunded === "" ? "..." : parseFloat(totalFunded).toLocaleString("en")}</span>
                                    <span>Total Funded</span>
                                </div>

                                <div className="d-flex flex-column align-items-center" style={{border:"1px dashed #AAB7B8",
                                    padding:"3px 10px 3px 10px", marginRight:20, borderRadius:4}}>
                                    <span style={{fontWeight:"bold"}}>₦{totalSpent === "" ? "..." : parseFloat(totalSpent).toLocaleString("en")}</span>
                                    <span>Total Spent</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading === false ? null : (
                    <LoadingContainer>
                        <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                    </LoadingContainer>
                )}
            </div>
        </div>
    );
}

export default PartnerActivities;