import * as BoxIcons from "react-icons/bi";
import * as React from "react";
import FundWalletDialog from "../../../modals/FundWalletDialog";
import {useEffect, useRef, useState} from "react";
import PriceSettingsDialog from "../../../modals/MotorbikePriceSettingsDialog";
import axios from "axios";
import {
    createInternationalShipmentURL,
    getAccountSettingsURL, getCompanySettings1URL,
    getDHLPickupLocationsURL,
    getShipmentPricingURL, updateAccountSettingsURL
} from "../../../library/URLs";
import {UserProfileData} from "../../../library/constants";
import styled from "styled-components";
import {generateTransactionID} from "../../../library/utilities";
import {SessionManagement} from "../../../library/SessionManagement";
import CancelShipmentDialog from "../../../modals/CancelShipmentDialog";
import AramexAccountDialog from "../../../modals/AramexAccountDialog";
import DHLAccountDialog from "../../../modals/DHLAccountDialog";

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const AccountSettings = () => {
    const form = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [intracityShipment, setIntracityShipment] = useState(true);
    const [interstateShipment, setInterstateShipment] = useState(true);
    const [internationalShipment, setInternationalShipment] = useState(true);

    //
    const [intracitySelfProcessor, setIntracitySelfProcessor] = useState(false);
    const [intracityDHLProcessor, setIntracityDHLProcessor] = useState(false);
    const [intracityTerminalProcessor, setIntracityTerminalProcessor] = useState(false);

    const [interstateSelfProcessor, setInterstateSelfProcessor] = useState(false);
    const [interstateDHLProcessor, setInterstateDHLProcessor] = useState(false);
    const [interstateTerminalProcessor, setInterstateTerminalProcessor] = useState(false);

    const [internationalSelfProcessor, setInternationalSelfProcessor] = useState(false);
    const [internationalDHLProcessor, setInternationalDHLProcessor] = useState(false);
    const [internationalTerminalProcessor, setInternationalTerminalProcessor] = useState(false);//

    const [intracityBikeVehicle, setIntracityBikeVehicle] = useState(true);
    const [intracityCarVehicle, setIntracityCarVehicle] = useState(true);
    const [intracityVanVehicle, setIntracityVanVehicle] = useState(true);
    const [intracityTruckVehicle, setIntracityTruckVehicle] = useState(true);

    const [interstateDHL, setInterstateDHL] = useState(false);
    const [interstateRedstar, setInterstateRedstar] = useState(false);

    const [internationalAramex, setInternationalAramex] = useState(false);
    const [internationalDHL, setInternationalDHL] = useState(false);
    const [internationalFedEx, setInternationalFedEx] = useState(false);
    const [internationalUPS, setInternationalUPS] = useState(false);

    const [cashPaymentMethod, setCashPaymentMethod] = useState(true);
    const [directTransferPaymentMethod, setDirectTransferPaymentMethod] = useState(true);
    const [cardPaymentMethod, setCardPaymentMethod] = useState(true);
    const [walletPaymentMethod, setWalletPaymentMethod] = useState(true);

    const [priceSettingsDialogVisibility, setPriceSettingsDialogVisibility] = useState(false);
    const [shipmentPricing, setShipmentPricing] = useState([]);

    const [showAramexAccountVisibility, setShowAramexAccountDialogVisibility] = useState(false);
    const [showDHLAccountVisibility, setShowDHLAccountDialogVisibility] = useState(false);

    useEffect(() => {
        const intracityProcessor = SessionManagement.getIntracityShipmentProcessor();
        const interstateProcessor = SessionManagement.getInterstateShipmentProcessor();
        const internationalProcessor = SessionManagement.getInternationalShipmentProcessor();

        if (intracityProcessor === "Self"){
            setIntracitySelfProcessor(true);
        } else if (intracityProcessor === "DHL"){
            setIntracityDHLProcessor(true);
        } else if (intracityProcessor === "Terminal"){
            setIntracityTerminalProcessor(true);
        }

        if (interstateProcessor === "Self"){
            setInterstateSelfProcessor(true);
        } else if (interstateProcessor === "DHL"){
            setInterstateDHLProcessor(true);
        } else if (interstateProcessor === "Terminal"){
            setInterstateTerminalProcessor(true);
        }

        if (internationalProcessor === "Self"){
            setInternationalSelfProcessor(true);
        } else if (internationalProcessor === "DHL"){
            setInternationalDHLProcessor(true);
        } else if (internationalProcessor === "Terminal"){
            setInternationalTerminalProcessor(true);
        }

        axios
            .post(getCompanySettings1URL, UserProfileData())
            .then(function (response) {
                console.log(response["data"]);
                if (response["data"]["success"] === true) {
                    const intracityShipment = response["data"]["data"]["local_shipment"];
                    const interstateShipment = response["data"]["data"]["interstate_shipment"];
                    const internationalShipment = response["data"]["data"]["international_shipment"];

                    const directTransfer = response["data"]["data"]["bank_transfer"];
                    const cash = response["data"]["data"]["cash"];
                    const cardPayment = response["data"]["data"]["card_payment"];
                    const wallet = response["data"]["data"]["wallet"];

                    const motorbike = response["data"]["data"]["motorbike"];
                    const car = response["data"]["data"]["car"];
                    const minivan = response["data"]["data"]["minivan"];
                    const truck = response["data"]["data"]["truck"];

                    setIntracityShipment(intracityShipment === "1");
                    setInterstateShipment(interstateShipment === "1");
                    setInternationalShipment(internationalShipment === "1")

                    console.log(cash);

                    setCashPaymentMethod(cash === "1");
                    setDirectTransferPaymentMethod(directTransfer === "1");
                    setCardPaymentMethod(cardPayment === "1");
                    setWalletPaymentMethod(wallet === "1");

                    setIntracityBikeVehicle(motorbike === "1");
                    setIntracityCarVehicle(car === "1");
                    setIntracityVanVehicle(minivan === "1");
                    setIntracityTruckVehicle(truck === "1");
                }
            })
            .catch(function (error) {
                return error;
            });
    }, [])


    const toggleIntracityProcessor = (intracityProcessor) => {
        if (intracityProcessor === "intracitySelfProcessor"){
            setIntracitySelfProcessor(true);
            setIntracityDHLProcessor(false);
            setIntracityTerminalProcessor(false);
        } else if (intracityProcessor === "intracityDHLProcessor"){
            setIntracitySelfProcessor(false);
            setIntracityDHLProcessor(true);
            setIntracityTerminalProcessor(false);
        } else if (intracityProcessor === "intracityTerminalProcessor"){
            setIntracitySelfProcessor(false);
            setIntracityDHLProcessor(false);
            setIntracityTerminalProcessor(true);
        }
    }

    const toggleInterstateProcessor = (interstateProcessor) => {
        if (interstateProcessor === "interstateSelfProcessor"){
            setInterstateSelfProcessor(true);
            setInterstateDHLProcessor(false);
            setInterstateTerminalProcessor(false);
        } else if (interstateProcessor === "interstateDHLProcessor"){
            setInterstateSelfProcessor(false);
            setInterstateDHLProcessor(true);
            setInterstateTerminalProcessor(false);
        } else if (interstateProcessor === "interstateTerminalProcessor"){
            setInterstateSelfProcessor(false);
            setInterstateDHLProcessor(false);
            setInterstateTerminalProcessor(true);
        }
    }

    const toggleInternationalProcessor = (internationalProcessor) => {
        if (internationalProcessor === "internationalSelfProcessor"){
            setInternationalSelfProcessor(true);
            setInternationalDHLProcessor(false);
            setInternationalTerminalProcessor(false);
        } else if (internationalProcessor === "internationalDHLProcessor"){
            setInternationalSelfProcessor(false);
            setInternationalDHLProcessor(true);
            setInternationalTerminalProcessor(false);
        } else if (internationalProcessor === "internationalTerminalProcessor"){
            setInternationalSelfProcessor(false);
            setInternationalDHLProcessor(false);
            setInternationalTerminalProcessor(true);
        }
    }

    const toggleShipmentTypeCheckBox = (shipmentType) => {
        if (shipmentType === "intracityShipment"){
            setIntracityShipment(!intracityShipment);
        } else if (shipmentType === "interstateShipment"){
            setInterstateShipment(!interstateShipment);
        } else if (shipmentType === "internationalShipment"){
            setInternationalShipment(!internationalShipment);
        }
    }

    const toggleShipmentVehicleCheckBox = (vehicleType) => {
        if (vehicleType === "intracityBikeVehicle"){
            setIntracityBikeVehicle(!intracityBikeVehicle);
        } else if (vehicleType === "intracityCarVehicle"){
            setIntracityCarVehicle(!intracityCarVehicle);
        } else if (vehicleType === "intracityVanVehicle"){
            setIntracityVanVehicle(!intracityVanVehicle);
        } else if (vehicleType === "intracityTruckVehicle"){
            setIntracityTruckVehicle(!intracityTruckVehicle);
        }
    }

    const toggleInternationalCarrier = (carrier) => {
        if (carrier === "Aramex"){
            setInternationalAramex(!internationalAramex);
        } else if (carrier === "DHL"){
            setInternationalDHL(!internationalDHL);
        } else if (carrier === "FedEx"){
            setInternationalFedEx(!internationalFedEx);
        } else if (carrier === "UPS"){
            setInternationalUPS(!internationalUPS);
        }
    }

    const toggleInterstateCarrier = (carrier) => {
        if (carrier === "DHL"){
            setInterstateDHL(!interstateDHL);
        } else if (carrier === "Redstar"){
            setInterstateRedstar(!interstateRedstar);
        }
    }

    const togglePaymentMethod = (paymentMethod) => {
        if (paymentMethod === "cash"){
            setCashPaymentMethod(!cashPaymentMethod);
        } else if (paymentMethod === "directTransfer"){
            setDirectTransferPaymentMethod(!directTransferPaymentMethod);
        } else if (paymentMethod === "cardPayment"){
            setCardPaymentMethod(!cardPaymentMethod);
        } else if (paymentMethod === "wallet"){
            setWalletPaymentMethod(!walletPaymentMethod);
        }
    }

    const updateAccountSettings = (e) => {
        e.preventDefault();

        setErrorVisibility(false);

        const data = new FormData(form.current);

        const formValues = {};

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setIsSubmitting(true);

        axios
            .post(updateAccountSettingsURL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    window.scrollTo(0, 0);

                    setSuccessVisibility(true);
                    setSuccessMessage(
                        "Account settings has been successfully updated"
                    );

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch(function (error) {
                return error;
            });
    };

    useEffect(() => {
        axios
            .post(getShipmentPricingURL, UserProfileData())
            .then(function (response) {
                if (response["data"]["success"] === true) {
                    setShipmentPricing(response["data"]);
                }
            })
            .catch(function (error) {
                return error;
            });
    }, []);

    const toggleAramexDialog = (type) => {
        type === "open" ? setShowAramexAccountDialogVisibility(true) : setShowAramexAccountDialogVisibility(false);
    }

    const toggleDHLDialog = (type) => {
        type === "open" ? setShowDHLAccountDialogVisibility(true) : setShowDHLAccountDialogVisibility(false);
    }

    return(
        <>
            <p style={{fontSize:22, marginTop:30, marginBottom:0, fontWeight:"bold"}}>Account Settings</p>
            <span>Set how you would want your business to operate</span>

            {
                isError === false ? null :
                    (<ErrorMessageContainer>
                        {errorMessage}
                    </ErrorMessageContainer>)
            }

            {
                isSuccess=== false ? null :
                    (<SuccessMessageContainer>
                        {successMessage}
                    </SuccessMessageContainer>)
            }

            <AramexAccountDialog
                isModalVisible={showAramexAccountVisibility}
                handleCloseDialog={(e) => toggleAramexDialog("close")}
            />

            <DHLAccountDialog
                isModalVisible={showDHLAccountVisibility}
                handleCloseDialog={(e) => toggleDHLDialog("close")}
            />

            <div className="row mt-4">
                <form ref={form} onSubmit={updateAccountSettings}>
                    <div className="col-xl-8 col-md-12">
                        <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderTop: "1px dashed #BFC9CA", borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Allowed Shipments</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="intracity_shipment" checked={intracityShipment} onChange={(e) => toggleShipmentTypeCheckBox("intracityShipment")}
                                           className="form-check-input" type="checkbox" value="1"  />
                                    <label className="form-check-label" htmlFor="allowedShipment">
                                        Intracity Shipment
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="interstate_shipment" checked={interstateShipment} onChange={(e) => toggleShipmentTypeCheckBox("interstateShipment")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="allowedShipment">
                                            Interstate Shipment
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="international_shipment" checked={internationalShipment} onChange={(e) => toggleShipmentTypeCheckBox("internationalShipment")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="allowedShipment">
                                            International Shipment
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Intracity Vehicles</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="intracity_bike" checked={intracityBikeVehicle} onChange={(e) => toggleShipmentVehicleCheckBox("intracityBikeVehicle")}
                                           className="form-check-input" type="checkbox" value="1"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Motor Bike
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="intracity_car" checked={intracityCarVehicle} onChange={(e) => toggleShipmentVehicleCheckBox("intracityCarVehicle")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Car
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="intracity_van" checked={intracityVanVehicle} onChange={(e) => toggleShipmentVehicleCheckBox("intracityVanVehicle")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Mini Van
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="intracity_truck" checked={intracityTruckVehicle} onChange={(e) => toggleShipmentVehicleCheckBox("intracityTruckVehicle")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Truck
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Intracity Processor</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="intracity_processor" checked={intracitySelfProcessor} onChange={(e) => toggleIntracityProcessor("intracitySelfProcessor")}
                                           className="form-check-input" type="radio" value="Self"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Self
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="intracity_processor" checked={intracityDHLProcessor} onChange={(e) => toggleIntracityProcessor("intracityDHLProcessor")}
                                               className="form-check-input" type="radio" value="DHL"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            DHL
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="intracity_processor" checked={intracityTerminalProcessor} onChange={(e) => toggleIntracityProcessor("intracityTerminalProcessor")}
                                               className="form-check-input" type="radio" value="Terminal"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Terminal
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Interstate Processor</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="interstate_processor"  checked={interstateSelfProcessor} onChange={(e) => toggleInterstateProcessor("interstateSelfProcessor")}
                                           className="form-check-input" type="radio" value="Self"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Self
                                    </label>
                                    {
                                        interstateSelfProcessor === true ?
                                            <div style={{width:"100%", marginLeft: 25}}>
                                                <div style={{marginTop:10}}>
                                                    <input name="interstate_carriers" checked={interstateDHL} onChange={(e) => toggleInterstateCarrier("DHL")}
                                                           className="form-check-input" type="checkbox" value="1"  />
                                                    <label className="form-check-label" htmlFor="interstateDHL">
                                                        DHL <span onClick={(e) => toggleDHLDialog("open")} style={{marginLeft:76, fontSize:13, color: "#008561", cursor: "pointer"}}>Update DHL Account</span>
                                                    </label>
                                                </div>

                                                <div style={{marginTop:10}}>
                                                    <input name="interstate_carriers" checked={interstateRedstar} onChange={(e) => toggleInterstateCarrier("Redstar")}
                                                           className="form-check-input" type="checkbox" value="1"  />
                                                    <label className="form-check-label" htmlFor="interstateRedstar">
                                                        Redstar <span style={{marginLeft:50, fontSize:13, color: "#008561", cursor: "pointer"}}>Update Redstar Account</span>
                                                    </label>
                                                </div>
                                            </div> :  null
                                    }

                                    <div style={{marginTop:10}}>
                                        <input name="interstate_processor" checked={interstateTerminalProcessor} onChange={(e) => toggleInterstateProcessor("interstateTerminalProcessor")}
                                               className="form-check-input" type="radio" value="Terminal"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Terminal
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>International Processor</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="international_processor" checked={internationalSelfProcessor} onChange={(e) => toggleInternationalProcessor("internationalSelfProcessor")}
                                           className="form-check-input" type="radio" value="Self"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Self
                                    </label>
                                    {
                                        internationalSelfProcessor === true ?
                                            <div style={{width:"100%", marginLeft: 25}}>
                                                <input name="interstate_carriers" checked={internationalAramex} onChange={(e) => toggleInternationalCarrier("Aramex")}
                                                       className="form-check-input" type="checkbox" value="1"  />
                                                <label className="form-check-label" htmlFor="localShipment">
                                                    Aramex <span onClick={(e) => toggleAramexDialog("open")} style={{marginLeft:50, fontSize:13, color: "#008561", cursor: "pointer"}}>Update Aramex Account</span>
                                                </label>

                                                <div style={{marginTop:10}}>
                                                    <input name="interstate_carriers" checked={internationalDHL} onChange={(e) => toggleInternationalCarrier("DHL")}
                                                           className="form-check-input" type="checkbox" value="1"  />
                                                    <label className="form-check-label" htmlFor="localShipment">
                                                        DHL <span onClick={(e) => toggleDHLDialog("open")} style={{marginLeft:76, fontSize:13, color: "#008561", cursor: "pointer"}}>Update DHL Account</span>
                                                    </label>
                                                </div>

                                                <div style={{marginTop:10}}>
                                                    <input name="interstate_carriers" checked={internationalFedEx} onChange={(e) => toggleInternationalCarrier("FedEx")}
                                                           className="form-check-input" type="checkbox" value="1"  />
                                                    <label className="form-check-label" htmlFor="localShipment">
                                                        FedEx <span style={{marginLeft:62, fontSize:13, color: "#008561", cursor: "pointer"}}>Update FedEx Account</span>
                                                    </label>
                                                </div>

                                                <div style={{marginTop:10}}>
                                                    <input name="interstate_carriers" checked={internationalUPS} onChange={(e) => toggleInternationalCarrier("UPS")}
                                                           className="form-check-input" type="checkbox" value="1"  />
                                                    <label className="form-check-label" htmlFor="localShipment">
                                                        UPS <span style={{marginLeft:78, fontSize:13, color: "#008561", cursor: "pointer"}}>Update UPS Account</span>
                                                    </label>
                                                </div>
                                            </div> :  null
                                    }

                                    <div style={{marginTop:10}}>
                                        <input name="international_processor" checked={internationalTerminalProcessor} onChange={(e) => toggleInternationalProcessor("internationalTerminalProcessor")}
                                               className="form-check-input" type="radio" value="Terminal"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Terminal
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Customer cancels shipment</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="cancel_shipment"
                                           className="form-check-input" type="radio" value="yes"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Refund to customer wallet
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="cancel_shipment"
                                               className="form-check-input" type="radio" value="yes"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Refund directly to bank account
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="cancel_shipment"
                                               className="form-check-input" type="radio" value="yes"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Do nothing
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Payment Methods</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="cash" checked={cashPaymentMethod} onChange={(e) => togglePaymentMethod("cash")}
                                           className="form-check-input" type="checkbox" value="1"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Cash
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="bank_transfer" checked={directTransferPaymentMethod} onChange={(e) => togglePaymentMethod("directTransfer")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Direct Transfer
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="card_payment" checked={cardPaymentMethod} onChange={(e) => togglePaymentMethod("cardPayment")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Card Payment
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="wallet" checked={walletPaymentMethod} onChange={(e) => togglePaymentMethod("wallet")}
                                               className="form-check-input" type="checkbox" value="1"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Wallet
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center py-2"  style={{borderBottom: "1px dashed #BFC9CA"}}>
                            <div className="d-flex flex-row" style={{width:"100%"}}>
                                <div style={{width:"45%"}}>
                                    <span style={{color:"#2C3E50"}}>Transporter Settings</span>
                                </div>
                                <div style={{width:"55%"}}>
                                    <input name="auto_arrange_routes"
                                           className="form-check-input" type="checkbox" value="yes"  />
                                    <label className="form-check-label" htmlFor="localShipment">
                                        Auto Arrange Routes for Intracity Shipments
                                    </label>

                                    <div style={{marginTop:10}}>
                                        <input name="positional_marking"
                                               className="form-check-input" type="checkbox" value="yes"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Positional Marking
                                        </label>
                                    </div>

                                    <div style={{marginTop:10}}>
                                        <input name="enforced_tracking"
                                               className="form-check-input" type="checkbox" value="yes"  />
                                        <label className="form-check-label" htmlFor="localShipment">
                                            Enforced Tracking
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row my-5 justify-content-end">
                            <SubmitButton type="submit" className="confirm-button">
                                {isSubmitting=== false ? (
                                    <span className="button-text">UPDATE SETTINGS</span>
                                ) : (
                                    <span className="spinner-border spinner-grow-sm"/>
                                )}
                            </SubmitButton>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AccountSettings;