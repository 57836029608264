import {useEffect, useState} from "react";
import axios from "axios";
import {getAllAdminsURL} from "../library/URLs";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {HiOutlineDotsHorizontal} from "react-icons/hi";
import * as React from "react";
import {UserProfileData} from "../library/constants";
import styled from "styled-components";
import * as BsIcon from "react-icons/bs";
import {useNavigate} from "react-router-dom";
import SubscriptionInactive from "../components/SubscriptionInactive";

const ProfileImage = styled.img`
    border-radius:50%;
    width:65px;
    height:65px;    
    cursor:pointer;
`;

const I4nnova = () => {
    const navigator = useNavigate();

    const [profileTabsNumber, setProfileTabsNumber] = useState(3);
    const [admins, setAdmins] = useState([]);
    const [isRetrieving, setIsRetrieving] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const[userActionMenuVisibility, setUserActionMenuVisibility] = useState(false);

    const toggleUserActionMenu1 = () => {
        setUserActionMenuVisibility(!userActionMenuVisibility);
    }

    const data = {
        session_id: "16e48fe6c44f06c684f983a6add0e38f"
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post("https://vpheemn.com/run-payroll.php", newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
            });
    }, []);

    const goToSingleAdmin = (adminID) => {
        navigator("/admin/" + adminID);
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                {/*{isError === true ? (*/}
                {/*    <ErrorNotification message={errorMessage}>*/}
                {/*        This is a notification*/}
                {/*    </ErrorNotification>*/}
                {/*) : null}*/}

                {/*{isSuccess === true ? (*/}
                {/*    <SuccessNotification message={successMessage}>*/}
                {/*        This is a notification*/}
                {/*    </SuccessNotification>*/}
                {/*) : null}*/}
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Admins</h5>
                        <span>Home</span>
                    </div>

                    {/*<MakeContractUserDialog*/}
                    {/*    isModalVisible={isContractUserModalVisible}*/}
                    {/*    handleCloseDialog={handleCloseModal}*/}
                    {/*    customerID={customerID}*/}
                    {/*    getContractDetails={true}*/}
                    {/*/>*/}

                    {/*<MakeRegularUserDialog*/}
                    {/*    isModalVisible={isRegularUserModalVisible}*/}
                    {/*    handleCloseDialog={handleCloseModal}*/}
                    {/*    customerID={customerID}*/}
                    {/*/>*/}

                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="d-flex justify-content-end">
                                <button onClick={()=> navigator("/create-admin")} style={{marginRight:22}} className="confirm-button d-flex justify-content-center">
                                    CREATE ADMIN
                                </button>
                            </div>
                            <div className="row mt-4">
                                {admins.map((admin) => {
                                    const toggleUserActionMenu = () => {
                                        console.log("hello");
                                    }
                                    return (
                                        <div key={admin.id} className="col-xl-3 col-md-6 mb-3 mr-3">
                                            <div className="card bg-light border-0 shadow">
                                                <div style={{padding:0}} className="card-body shipper-container">
                                                    <div className="d-flex flex-column align-items-center">
                                                        <div style={{width:"100%"}} className="d-flex justify-content-end">
                                                            <div className="user-menu-container">
                                                                <HiOutlineDotsHorizontal onClick={toggleUserActionMenu} style={{width:20, cursor:"pointer", marginRight:10}} className="icon"/>
                                                                <div className={`user-menu ${userActionMenuVisibility ? "user-menu-visible" : null}`}>
                                                                    <span>Fund Wallet</span>
                                                                    <span>Deduct From Wallet</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ProfileImage onClick={(e)=>goToSingleAdmin(admin.id)} src={admin.profile_image_url} />
                                                        <div style={{textAlign: 'center', marginTop:20}}>
                                                            <span className="title">{admin.name}</span>
                                                            <p>{admin.employee_type}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-light d-flex justify-content-around">
                                                    <div>
                                                        Profile
                                                    </div>
                                                    <div>
                                                        Activities
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default I4nnova