import axios from "axios";
import React, { useEffect, useState } from "react";
import { getCashbacksUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import SubscriptionInactive from "../components/SubscriptionInactive";
import styled from "styled-components";
import AddCashbackDialog from "../modals/AddCashbackDialog";
import EditCashbackDialog from "../modals/EditCashbackDialog";
import UpdateCashbackStatusDialog from "../modals/UpdateCashbackStatusDialog";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Cashbacks = () => {
  // };
  const [cashbacks, setCashbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showAddCashback, setShowAddCashback] = useState(false);
  const [showEditCashback, setShowEditCashback] = useState(null);
  const [showUpdateCashbackStatus, setShowUpdateCashbackStatus] = useState(null);

  const newData = { ...UserProfileData() };
  const fetchCashbacks = async () => {
    setIsLoading(true);
    await axios
      .post(getCashbacksUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const cashbacks = response["data"]["data"];

        setCashbacks(cashbacks);
      });
  };

  const openAddCashback = () => {
    setShowAddCashback(true);
  };

  const closeAddCashback = () => {
    setShowAddCashback(false);
  };

  const openEditCashback = (cashback) => {
    setShowEditCashback({...cashback});
  };

  const closeEditCashback = () => {
    setShowEditCashback(null);
  };

  const openActivateCashbackStatus = (cashback) => {
    setShowUpdateCashbackStatus({
      id: cashback?.id,
      status: "Active"
    });
  };

  const openDeactivateCashbackStatus = (cashback) => {
    setShowUpdateCashbackStatus({
      id: cashback?.id,
      status: "Inactive"
    });
  };

  const closeUpdateCashbackStatus = () => {
    setShowUpdateCashbackStatus(null);
  };

  useEffect(() => {
    fetchCashbacks();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <AddCashbackDialog 
        isModalVisible={showAddCashback}
        handleCloseDialog={closeAddCashback}
        getCashbacks={fetchCashbacks}
      />
      <EditCashbackDialog 
        isModalVisible={showEditCashback !== null}
        handleCloseDialog={closeEditCashback}
        getCashbacks={fetchCashbacks}
        cashback={showEditCashback}
      />
      <UpdateCashbackStatusDialog 
        isModalVisible={showUpdateCashbackStatus !== null}
        handleCloseDialog={closeUpdateCashbackStatus}
        getCashbacks={fetchCashbacks}
        cashback={showUpdateCashbackStatus}
      />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h5>Cashbacks</h5>
            <span>Home</span>
          </div>
          <div className="container-fluid shipments-container">
            <div className="card bg-light border-0 shadow p-4 min-vh-100">
              <div className="d-flex justify-content-end mb-4">
                <button
                  onClick={openAddCashback}
                  style={{ marginRight: 22 }}
                  className="confirm-button d-flex justify-content-center"
                >
                  ADD CASHBACK
                </button>
              </div>
              <table className="shipments-table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Minimum Spent</th>
                    <th scope="col">Maximum Spent</th>
                    <th scope="col">Cashback Value(%)</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {isLoading === true ? null : (
                  <tbody>
                    {cashbacks.map((cashback) => {
                      // const status = shipment.status !== "" ? shipment.status : "Pending Delivery";

                      return (
                        <tr key={cashback.id}>
                          <td>{cashback.name}</td>
                          <td>
                            ₦{" "}
                            {parseFloat(cashback.min_amount).toLocaleString(
                              "en"
                            )}
                          </td>
                          <td>
                            ₦
                            {parseFloat(cashback.max_amount).toLocaleString(
                              "en"
                            )}
                          </td>
                          <td>{cashback.percent_value}</td>
                          <td>{cashback.status}</td>
                          <td>
                            <div className="d-flex gap-2">
                              <button
                                onClick={()=>openEditCashback(cashback)}
                                className="confirm-button d-flex justify-content-center"
                              >
                                Edit
                              </button>
                              {
                                cashback.status === "Active" ? (
                                  <button
                                    onClick={()=>openDeactivateCashbackStatus(cashback)}
                                    className="confirm-button btn-inactive d-flex justify-content-center"
                                  >
                                    Deactivate
                                  </button>
                                ) : (
                                  <button
                                    onClick={()=>openActivateCashbackStatus(cashback)}
                                    className="confirm-button btn-active d-flex justify-content-center"
                                  >
                                    Activate
                                  </button>
                                )
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cashbacks;
