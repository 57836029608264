import axios from "axios";
import { useState } from "react";
import { BsX } from "react-icons/bs";
import { FaFileAlt } from "react-icons/fa";
import styled from "styled-components";
import { uploadAdminRateDocumentUrl } from "../../../library/URLs";
import UploadRateDocumentDialog from "../../../modals/UploadRateDocumentDialog";

const SubmitButton = styled.button`
  width: 250px;
`;

const AdminRateCards = ({
  setErrorVisibility,
  setSuccessMessage,
  setSuccessVisibility,
  setErrorMessage,
}) => {
  const [fileDocs, setFileDocs] = useState({
    export_dhl: null,
    export_ups: null,
    export_aramex: null,
    export_fedex_economy: null,
    export_fedex_priority_express: null,
    export_fedex_priority: null,
    parcelflow_rates: null,
    export_anka: null
  });
  const [filename, setFilename] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const cancelDoc = (name) => {
    setFileDocs({
      ...fileDocs,
      [name]: null,
    });
  };

  // Check if all document fields are null
  const checkDocNull = () => {
    for (const key in fileDocs) {
      if (fileDocs.hasOwnProperty(key) && fileDocs[key] !== null) {
        return false;
      }
    }

    return true;
  };

  const selectFileDoc = (e) => {
    if (!checkDocNull()) {
      flagUploadInWait();
      return;
    }

    const { files, name } = e.target;
    let file = files[0];
    setFileDocs({
      ...fileDocs,
      [name]: file,
    });
    setFilename(file?.name);
  };

  const flagUploadInWait = () => {
    setErrorMessage(
      "Please a file is already selected. Ensure to cancel selected file or upload before selecting another!"
    );
    setErrorVisibility(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      setErrorVisibility(false);
      setErrorMessage("");
    }, 6000);
  };

  const flagEmptyDocs = () => {
    setErrorMessage("Please select a document to upload!");
    setErrorVisibility(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      setErrorVisibility(false);
      setErrorMessage("");
    }, 6000);
  };

  const uploadDocument = () => {
    let formData = new FormData();

    if (fileDocs.export_dhl) {
      formData.append("rate_file", fileDocs.export_dhl);
      formData.append("rate_type", "export-dhl");
    } else if (fileDocs.export_ups) {
      formData.append("rate_file", fileDocs.export_ups);
      formData.append("rate_type", "export-ups");
    } else if (fileDocs.export_aramex) {
      formData.append("rate_file", fileDocs.export_aramex);
      formData.append("rate_type", "export-aramex");
    } else if (fileDocs.export_fedex_economy) {
      formData.append("rate_file", fileDocs.export_fedex_economy);
      formData.append("rate_type", "export-fedex-economy");
    } else if (fileDocs.export_fedex_priority) {
      formData.append("rate_file", fileDocs.export_fedex_priority);
      formData.append("rate_type", "export-fedex-priority");
    } else if (fileDocs.export_fedex_priority_express) {
      formData.append("rate_file", fileDocs.export_fedex_priority_express);
      formData.append("rate_type", "export-fedex-priority-express");
    } else if (fileDocs.parcelflow_rates) {
      formData.append("rate_file", fileDocs.parcelflow_rates);
      formData.append("rate_type", "parcelflow-rates");
    } else if (fileDocs.export_anka) {
      formData.append("rate_file", fileDocs.export_anka);
      formData.append("rate_type", "dhl-anka");
    }

    setIsPending(true);

    axios
      .post(uploadAdminRateDocumentUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setIsPending(false);

        if(res?.data?.success === false) {
          setErrorMessage(res?.data?.message);
          setErrorVisibility(true);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });

          setTimeout(() => {
            setErrorVisibility(false);
            setErrorMessage("");
          }, 6000);
          return;
        }

        setFileDocs({
          export_dhl: null,
          export_aramex: null,
          export_fedex_economy: null,
          export_fedex_priority: null,
          export_fedex_priority_express: null,
          export_ups: null,
          parcelflow_rates: null,
          export_anka: null
        });

        setSuccessMessage("Document Uploaded.");
        setSuccessVisibility(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setTimeout(() => {
          setSuccessVisibility(false);
          setSuccessMessage("");
          window.location.reload();
        }, 3000);
      })
      .catch(function (error) {
        setIsPending(false);

        setErrorMessage("An error occured");
        setErrorVisibility(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setTimeout(() => {
          setErrorVisibility(false);
          setErrorMessage("");
        }, 6000);
        return;
      });
  };

  const showSampleDoc = (name) => {
    if (name === "export_dhl") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/export-dhl.xlsx",
        "_blank"
      );
    } else if (name === "export_ups") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/export-ups.xlsx",
        "_blank"
      );
    } else if (name === "export_aramex") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/export-aramex.xlsx",
        "_blank"
      );
    } else if (name === "export_fedex_economy") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/export-fedex-economy.xlsx",
        "_blank"
      );
    } else if (name === "export_fedex_priority") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/export-fedex-priority.xlsx",
        "_blank"
      );
    } else if (name === "export_fedex_priority_express") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/export-fedex-priority-express.xlsx",
        "_blank"
      );
    } else if (name === "parcelflow_rates") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/parcelflow-rates.xlsx",
        "_blank"
      );
    } else if (name === "export_anka") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/export-dhl.xlsx",
        "_blank"
      );
    } 
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkDocNull()) {
      flagEmptyDocs();
      return;
    }

    openShowModal();
  };

  const openShowModal = () => {
    setShowModal(true);
  };

  const closeShowModal = () => {
    setShowModal(false);
  };

  return (
    <div className="pt-5" >
      <UploadRateDocumentDialog
        isModalVisible={showModal}
        handleCloseDialog={closeShowModal}
        submit={uploadDocument}
        isSubmitting={isPending}
      />
      <p
        style={{
          fontSize: 22,
          marginTop: 30,
          marginBottom: 0,
          fontWeight: "bold",
        }}
      >
        Admin Rate Cards
      </p>
      <span>Upload admin rate spreadsheets to update shipment pricing costs.</span>
      <div className="row mt-4 rate-sections">
        {/* Aramex Rates */}
        <div
          className={`rate-card ${
            fileDocs?.export_aramex && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.export_aramex && (
              <BsX
                onClick={() => cancelDoc("export_aramex")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Aramex</p>
              <p className="rate-desc">
                Upload document to update Aramex Rates.
              </p>
              {fileDocs?.export_aramex && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("export_aramex")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="export_aramex"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        {/* DHL Routely Rates */}
        <div
          className={`rate-card ${
            fileDocs?.export_anka && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.export_anka && (
              <BsX
                onClick={() => cancelDoc("export_anka")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">DHL (ANKA)</p>
              <p className="rate-desc">
                Upload document to update DHL ANKA Rates.
              </p>
              {fileDocs?.export_anka && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("export_anka")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="export_anka"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        {/* DHL Parcelflow Rates */}
        <div
          className={`rate-card ${
            fileDocs?.parcelflow_rates && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.parcelflow_rates && (
              <BsX
                onClick={() => cancelDoc("parcelflow_rates")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">DHL (Parcelflow)</p>
              <p className="rate-desc">
                Upload document to update DHL Parcelflow Rates.
              </p>
              {fileDocs?.parcelflow_rates && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("parcelflow_rates")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="parcelflow_rates"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        {/* DHL Routely Rates */}
        <div
          className={`rate-card ${
            fileDocs?.export_dhl && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.export_dhl && (
              <BsX
                onClick={() => cancelDoc("export_dhl")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">DHL (Routely)</p>
              <p className="rate-desc">
                Upload document to update DHL Rates.
              </p>
              {fileDocs?.export_dhl && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("export_dhl")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="export_dhl"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        {/* Fedex Economy Rates */}
        <div
          className={`rate-card ${
            fileDocs?.export_fedex_economy && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.export_fedex_economy && (
              <BsX
                onClick={() => cancelDoc("export_fedex_economy")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Fedex Economy</p>
              <p className="rate-desc">
                Upload document to update Fedex Economy Rates.
              </p>
              {fileDocs?.export_fedex_economy && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("export_fedex_economy")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="export_fedex_economy"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        {/* Fedex Priority Rates */}
        <div
          className={`rate-card ${
            fileDocs?.export_fedex_priority && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.export_fedex_priority && (
              <BsX
                onClick={() => cancelDoc("export_fedex_priority")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Fedex Priority</p>
              <p className="rate-desc">
                Upload document to update Fedex Priority Rates.
              </p>
              {fileDocs?.export_fedex_priority && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("export_fedex_priority")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="export_fedex_priority"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        {/* Fedex Priority Express Rates */}
        <div
          className={`rate-card ${
            fileDocs?.export_fedex_priority_express && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.export_fedex_priority_express && (
              <BsX
                onClick={() => cancelDoc("export_fedex_priority_express")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Fedex Priority Express</p>
              <p className="rate-desc">
                Upload document to update Fedex Priority Express Rates.
              </p>
              {fileDocs?.export_fedex_priority_express && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("export_fedex_priority_express")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="export_fedex_priority_express"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        {/* UPS Rates */}
        <div
          className={`rate-card ${
            fileDocs?.export_ups && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.export_ups && (
              <BsX
                onClick={() => cancelDoc("export_ups")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">UPS Savers</p>
              <p className="rate-desc">
                Upload document to update UPS Rates.
              </p>
              {fileDocs?.export_ups && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("export_ups")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="export_ups"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

      </div>
      <div className="d-flex flex-row my-5 justify-content-start">
        <SubmitButton
          type="button"
          className="confirm-button"
          onClick={handleSubmit}
        >
          {isPending === false ? (
            <span className="button-text">UPLOAD RATE</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </div>
    </div>
  );
};

export default AdminRateCards;
