import {Col, Row} from "react-bootstrap";
import {Switch} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import styled from "styled-components";

const SubmitButton = styled.button`
  width: 250px;
`;

const NotificationSettings = () => {
    const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);

    const [createShipment, setCreateShipment] = useState(false);
    const [approveShipment, setApproveShipment] = useState(false);
    const [topupUserWallet, setTopupUserWallet] = useState(false);
    const [deductUserWallet, setDeductUserWallet] = useState(false);

    const [customerCreateAccount, setCustomerCreateAccount] = useState(false);
    const [customerCalculateCost, setCustomerCalculateCost] = useState(false);
    const [customerCreateShipment, setCustomerCreateShipment] = useState(false);
    const [customerSendsMessage, setCustomerSendsMessage] = useState(false);
    const [customerLogsIn, setCustomerLogsIn] = useState(false);

    const [transporterAcceptsShipments, setTransporterAcceptsShipments] = useState(false);
    const [transporterPicksUp, setTransporterPicksUp] = useState(false);
    const [transporterDelivers, setTransporterDelivers] = useState(false);
    const [transporterRejects, setTransporterRejects] = useState(false);

    return (
        <>
            <p style={{fontSize:22, marginTop:30, marginBottom:0, fontWeight:"bold"}}>Notification Settings</p>
            <span>Choose what you would like to be notified about</span>
            <Row className="form-row mt-4">
                <Col className="form-group col-xl-4 col-md-6">
                    <span style={{fontWeight:"bold"}}>Employee Notifications</span>
                    <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Create Shipment</label>
                            <Switch defaultValue="0" name="create_shipment" onChange={() => setCreateShipment(!createShipment)} checked={createShipment} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Approve Shipment</label>
                            <Switch name="approve_shipment" onChange={() => setApproveShipment(!approveShipment)} checked={approveShipment} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Topup User Wallet</label>
                            <Switch name="route_shipment" onChange={() => setTopupUserWallet(!topupUserWallet)} checked={topupUserWallet} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Deduct User Wallet</label>
                            <Switch name="route_shipment" onChange={() => setDeductUserWallet(!deductUserWallet)} checked={deductUserWallet} className="react-switch" />
                        </div>
                    </div>
                </Col>

                <Col className="form-group col-xl-4 col-md-6">
                    <span style={{fontWeight:"bold"}}>Customer Notifications</span>
                    <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Create Account</label>
                            <Switch name="approve_wallet_topup" onChange={() => setCustomerCreateAccount(!customerCreateAccount)} checked={customerCreateAccount} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Calculate Cost</label>
                            <Switch name="mark_payment_as_paid" onChange={() => setCustomerCalculateCost(!customerCalculateCost)} checked={customerCalculateCost} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Create Shipment</label>
                            <Switch name="mark_payment_as_unpaid" onChange={() => setCustomerCreateShipment(!customerCreateShipment)} checked={customerCreateShipment} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Sends Message</label>
                            <Switch name="fund_user_wallet" onChange={() => setCustomerSendsMessage(!customerSendsMessage)} checked={customerSendsMessage} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Logs In</label>
                            <Switch name="deduct_user_wallet" onChange={() => setCustomerLogsIn(!customerLogsIn)} checked={customerLogsIn} className="react-switch" />
                        </div>
                    </div>
                </Col>

                <Col className="form-group col-xl-4 col-md-6">
                    <span style={{fontWeight:"bold"}}>Transporter Notifications</span>
                    <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Accepts Shipments</label>
                            <Switch name="send_push_notifications" onChange={() => setTransporterAcceptsShipments(!transporterAcceptsShipments)} checked={transporterAcceptsShipments} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Picks Up Shipments</label>
                            <Switch name="send_email" onChange={() => setTransporterPicksUp(!transporterPicksUp)} checked={transporterPicksUp} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Delivers Shipments</label>
                            <Switch name="send_sms" onChange={() => setTransporterDelivers(!transporterDelivers)} checked={transporterDelivers} className="react-switch" />
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <label htmlFor="Full Name">Rejects Shipment</label>
                            <Switch name="make_contract_user" onChange={() => setTransporterRejects(!transporterRejects)} checked={transporterRejects} className="react-switch" />
                        </div>
                    </div>
                </Col>
            </Row>

            <div className="d-flex flex-row my-5 justify-content-end">
                <SubmitButton type="button" className="confirm-button">
                    {isCreatingAdmin === false ? (
                        <span className="button-text">UPDATE NOTIFICATIONS</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm"/>
                    )}
                </SubmitButton>
            </div>
        </>
    )
}

export default NotificationSettings;