import { Col, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { updateCurrencyRateUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import { BiX } from "react-icons/bi";
import * as React from "react";
import currencies from "../data/currencies.json";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const UpdateCurrencyDialog = ({
  isModalVisible,
  handleCloseDialog,
  currencyRate,
  fetchCurrencies,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (currencyRate) {
      setFormData({
        currency: currencyRate.currency,
        rate_value: currencyRate.rate_value,
      });
    }
  }, [currencyRate]);

  const [formData, setFormData] = useState({
    currency: "",
    rate_value: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const updateCurrencyRate = async () => {
    setIsSubmitting(true);

    if (formData.currency === "" || formData.rate_value === "") {
      setErrorVisibility(true);
      setErrorMessage("Please ensure you fill all values");

      setTimeout(() => {
        setErrorVisibility(false);
      }, 5000);
      return;
    }

    await axios
      .post(updateCurrencyRateUrl, { ...UserProfileData(), ...formData })
      .then((response) => {
        setIsSubmitting(false);
        if (response["data"]["success"]) {
          setSuccessVisibility(true);
          setSuccessMessage(response["data"]["data"]);

          setTimeout(() => {
            handleCloseDialog();
            fetchCurrencies();
            setSuccessVisibility(false);
          }, 3000);
        } else {
          setErrorVisibility(true);
          setErrorMessage(response["data"]["message"]);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{ marginTop: 10 }} className="header">
          {currencyRate?.currency ? "UPDATE" : "ADD"} CURRENCY RATE
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <div>
          {/*<div className="d-flex flex-row justify-content-evenly">*/}
          {/*    <div style={{width: "100%", textAlign: "center", cursor:"pointer"}}><span>Standard Pricing</span></div>*/}
          {/*    <div  style={{width: "100%", textAlign: "center", cursor:"pointer"}}><span>Express Pricing</span></div>*/}
          {/*</div>*/}
          <div>
            <div className="mt-2">
              <label htmlFor="Currency">Currency</label>
              <select
                name="currency"
                value={formData.currency}
                onChange={handleInput}
                className="form-control"
              >
                <option value="" hidden>
                  Select Currency
                </option>
                {currencies.map((curr) => (
                  <option key={curr.cc} value={curr.cc}>
                    {curr.name}-{curr.symbol}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-3">
              <label htmlFor="Rate Value">Rate Value</label>
              <input
                name="rate_value"
                value={formData.rate_value}
                onChange={handleInput}
                className="form-control"
                type="tel"
                placeholder="Enter Rate Value"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
        <SubmitButton
          type="button"
          onClick={updateCurrencyRate}
          className="confirm-button"
        >
          {isSubmitting === false ? (
            <span className="button-text">UPDATE CURRENCIES</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateCurrencyDialog;
