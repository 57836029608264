import {Button, Col, Modal, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {
    createInternationalShipmentURL,
    fundUserWalletURL,
    getContractDetailsUserURL,
    makeContractUserURL, topupUserWalletURL
} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {BiX} from "react-icons/bi";
import * as React from "react";
import {SessionManagement} from "../library/SessionManagement";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const FundWalletDialog = ({ isModalVisible, handleCloseDialog, customerID, phoneNumber, customerName }) => {
    const [amount, setAmount] = useState("20000");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const name = SessionManagement.getName();
    const email = SessionManagement.getEmail();
    const companyName = SessionManagement.getCompanyName();
    const companyID = SessionManagement.getCompanyID();

    const config = {
        public_key: 'FLWPUBK-8025906ad5029787e3a1e30a3236411c-X',
        tx_ref: Date.now(),
        amount: +amount,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: email,
            phone_number: '08038303787',
            name: name,
        },
        customizations: {
            title: 'Orbeet',
            description: 'Fund wallet for ' + companyName,
            logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
    };

    // const handleFlutterPayment = useFlutterwave(config);

    const callFlutterwave = () => {
        // handleFlutterPayment({
        //     callback: (response) => {
        //         console.log(response);
        //         closePaymentModal() // this will close the modal programmatically
        //     },
        //     onClose: () => {},
        // });
    }

    const onAmountChange = (e) => {
        const amount = e.target.value;
    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">FUND WALLET</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <div>
              <div className="mt-2">
                  <label htmlFor="Amount">Amount</label>
                  <input onChange={onAmountChange} name="amount" value={amount} className="form-control" type="number" />
              </div>
          </div>

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={(e) => callFlutterwave()} type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">FUND WALLET</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default FundWalletDialog;
