import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import packageIcon from "../../images/package.jpg";
import userIcon from "../../images/user.png";
import transporterIcon from "../../images/transporter.png";
import * as React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const ProfileImage = styled.img`
    border-radius:50%;
    width:65px;
    height:65px;    
`;

const ReportsHome = () => {
    const navigator = useNavigate();

    const viewReport = (type) => {
        if (type === "Shipments"){
            navigator("/reports/shipments");
        } else if (type === "Customers"){
            navigator("/reports/customers");
        } else if (type === "Transporters"){
            navigator("/reports/transporters");
        }
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Reports</h5>
                        <span>Home</span>
                    </div>

                    <div className="container">
                        <div className="row mt-4">
                            <div className="col-xl-4">
                                <div onClick={()=> viewReport("Shipments")} style={{cursor:"pointer"}} className="card bg-light border-0 shadow p-4 d-flex flex-row justify-content-center">
                                    <img style={{width:45, height:45, marginRight:10}} src={packageIcon} alt="" />
                                    <div className="d-flex flex-column">
                                        <h6 style={{fontSize:18, fontWeight:"bold", margin:0, textAlign:"left"}}>SHIPMENT REPORTS</h6>
                                        <p style={{fontSize:14, textAlign:"left", margin:0}}>For all reports associated with shipment</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4">
                                <div onClick={()=> viewReport("Customers")} style={{cursor:"pointer"}} className="card bg-light border-0 shadow p-4 d-flex flex-row justify-content-center">
                                    <img style={{width:45, height:45, marginRight:10}} src={userIcon} alt="" />
                                    <div className="d-flex flex-column">
                                        <h6 style={{fontSize:18, fontWeight:"bold", margin:0, textAlign:"left"}}>CUSTOMER REPORTS</h6>
                                        <p style={{fontSize:14, textAlign:"left", margin:0}}>For all reports associated with customers </p>
                                    </div>
                                </div>
                            </div>

                            {/*<div className="col-xl-4">*/}
                            {/*    <div onClick={()=> viewReport("Transporters")} style={{cursor:"pointer"}} className="card bg-light border-0 shadow p-4 d-flex flex-row justify-content-center">*/}
                            {/*        <img style={{width:45, height:45, marginRight:10}} src={transporterIcon} alt="" />*/}
                            {/*        <div className="d-flex flex-column">*/}
                            {/*            <h6 style={{fontSize:18, fontWeight:"bold", margin:0, textAlign:"left"}}>TRANSPORTER REPORTS</h6>*/}
                            {/*            <p style={{fontSize:14, textAlign:"left", margin:0}}>For all reports associated with transporter </p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportsHome