import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {UserProfileData} from "../../../library/constants";
import axios from "axios";
import {
    editAdminPrivilegesURL,
    getAdminActivitiesURL, getAdminPrivilegesURL
} from "../../../library/URLs";
import styled from "styled-components";
import * as BoxIcons from "react-icons/bi";
import {Col, Row} from "react-bootstrap";
import {Switch} from "@mui/material";
import moment from "moment";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    margin-top:30px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    margin-top:30px;
    text-align:center;
`;

const TransporterActivities = ({adminID, adminPrivileges}) => {
    const navigator = useNavigate();

    const form = useRef(null);

    const [isUpdatingAdmin, setIsUpdatingAdmin] = useState(false);

    const [activeTab, setActiveTab] = useState("AdminActivities");

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [adminActivities, setAdminActivities] = useState([]);

    const [createShipment, setCreateShipment] = useState(false);
    const [approveShipment, setApproveShipment] = useState(false);
    const [routeShipment, setRouteShipment] = useState(false);
    const [rerouteShipment, setRerouteShipment] = useState(false);
    const [cancelShipment, setCancelShipment] = useState(false);
    const [rejectShipment, setRejectShipment] = useState(false);
    const [transferShipment, setTransferShipment] = useState(false);

    const [approveWalletTopup, setApproveWalletTopup] = useState(false);
    const [markPaymentAsPaid, setMarkPaymentAsPaid] = useState(false);
    const [markPaymentAsUnpaid, setMarkPaymentAsUnpaid] = useState(false);
    const [fundUserWallet, setFundUserWallet] = useState(false);
    const [deductUserWallet, setDeductUserWallet] = useState(false);

    const [sendPushNotifications, setSendPushNotifications] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [sendSMS, setSendSMS] = useState(false);
    const [makeContractUser, setMakeContractUser] = useState(false);
    const [makeRegularUser, setMakeRegularUser] = useState(false);
    const [replyMessages, setReplyMessages] = useState(false);

    const [createTransporter, setCreateTransporter] = useState(false);
    const [changeTransporterDetails, setChangeTransporterDetails] = useState(false);
    const [deactivateTransporter, setDeactivateTransporter] = useState(false);
    const [reactivateTransporter, setReactivateTransporter] = useState(false);
    const [deleteTransporter, setDeleteTransporter] = useState(false);

    const [transferFunds, setTransferFunds] = useState(false);
    const [buyAirtime, setBuyAirtime] = useState(false);
    const [buyElectricity, setBuyElectricity] = useState(false);
    const [buyInternet, setBuyInternet] = useState(false);
    const [buyToll, setBuyToll] = useState(false);

    const [createAdmin, setCreateAdmin] = useState(false);
    const [editAdmin, setEditAdmin] = useState(false);
    const [suspendAdmin, setSuspendAdmin] = useState(false);
    const [unsuspendAdmin, setUnsuspendAdmin] = useState(false);
    const [deleteAdmin, setDeleteAdmin] = useState(false);

    const data = {
        employee_id: adminID,
        limit: 20,
        offset: 0,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getAdminActivitiesURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const adminActivities = response["data"]["data"];

                console.log(adminActivities.length);

                if (adminActivities.length === 0)
                    setIsEmpty(true);

                setAdminActivities(adminActivities);
            });
    }, []);

    useEffect(() => {
        axios
            .post(getAdminPrivilegesURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);

                if (response["data"]["success"] === false) return;

                setCreateShipment(response["data"]["data"]['create_shipment'] === "1");
                setApproveShipment(response["data"]["data"]['approve_shipment'] === "1");
                setRouteShipment(response["data"]["data"]['route_shipment'] === "1");
                setRerouteShipment(response["data"]["data"]['reroute_shipment'] === "1");
                setCancelShipment(response["data"]["data"]['cancel_shipment'] === "1");
                setRejectShipment(response["data"]["data"]['reject_shipment'] === "1");
                setTransferShipment(response["data"]["data"]['transfer_shipment'] === "1");

                setApproveWalletTopup(response["data"]["data"]['approve_wallet_topup'] === "1");
                setMarkPaymentAsPaid(response["data"]["data"]['mark_payment_as_paid'] === "1");
                setMarkPaymentAsUnpaid(response["data"]["data"]['mark_payment_as_unpaid'] === "1");
                setFundUserWallet(response["data"]["data"]['fund_user_wallet'] === "1");
                setDeductUserWallet(response["data"]["data"]['deduct_user_wallet'] === "1");

                setSendPushNotifications(response["data"]["data"]['send_push_notifications'] === "1");
                setSendEmail(response["data"]["data"]['send_email'] === "1");
                setSendSMS(response["data"]["data"]['send_sms'] === "1");
                setMakeContractUser(response["data"]["data"]['make_contract_user'] === "1");
                setMakeRegularUser(response["data"]["data"]['make_regular_user'] === "1");
                setReplyMessages(response["data"]["data"]['reply_messages'] === "1");

                setCreateTransporter(response["data"]["data"]['create_rider'] === "1");
                setChangeTransporterDetails(response["data"]["data"]['change_rider_details'] === "1");
                setDeactivateTransporter(response["data"]["data"]['deactivate_rider'] === "1");
                setReactivateTransporter(response["data"]["data"]['reactivate_rider'] === "1");
                setDeleteTransporter(response["data"]["data"]['delete_rider'] === "1");

                setTransferFunds(response["data"]["data"]['transfer_funds'] === "1");
                setBuyAirtime(response["data"]["data"]['buy_airtime'] === "1");
                setBuyElectricity(response["data"]["data"]['buy_electricity'] === "1");
                setBuyInternet(response["data"]["data"]['buy_internet'] === "1");
                setBuyToll(response["data"]["data"]['buy_toll'] === "1");

                setCreateAdmin(response["data"]["data"]['create_employee'] === "1");
                setEditAdmin(response["data"]["data"]['edit_employee'] === "1");
                setSuspendAdmin(response["data"]["data"]['suspend_employee'] === "1");
                setUnsuspendAdmin(response["data"]["data"]['unsuspend_employee'] === "1");
                setDeleteAdmin(response["data"]["data"]['delete_employee'] === "1");
            });
    }, []);

    const updateAdmin = () => {
        const formValues = {};
        formValues["privileges"] = {};
        formValues["employee_id"] = adminID;

        const data = new FormData(form.current);

        for (const [key, value] of data.entries()) {
            formValues["privileges"][key] = "1";
        }

        const newData = { ...formValues, ...UserProfileData() };

        setIsUpdatingAdmin(true);
        axios
            .post(editAdminPrivilegesURL, newData)
            .then((response) => {
                console.log(response);
                setIsUpdatingAdmin(false);
                window.scrollTo(0, 0);

                if (response["data"]["success"] === true){
                    setSuccessVisibility(true);
                    setSuccessMessage("Successfully updated admin.");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                    }, 3000);
                } else {
                    const message = response['data']['message'];

                    setErrorVisibility(true);
                    setErrorMessage(message);
                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 3000);
                }
            })
            .catch(error => console.log(error));
    }

    const goToPage = (type, typeID) => {
        if (type === "Shipment"){
            navigator("/shipment/" + typeID);
        } else if (type === "Customer"){
            navigator("/customer/" + typeID);
        } else if (type === "Transporter"){
            navigator("/transporter/" + typeID);
        }
    };

    return (
        <div className="card bg-light border-0 shadow">
            <div className="card-body">
                <div>
                    <span onClick={(e)=> setActiveTab("AdminActivities")} className={`profile-tab ${activeTab === "AdminActivities" ? "active-profile" : null}`}>Activities</span>
                </div>

                <div className={`${activeTab === "AdminActivities" ? "show-customer-tab" : "hide-customer-tab"}`}>
                    {
                        adminActivities.length === 0 ?
                            <EmptyContainer>
                                Transporter has no recorded activities yet...
                            </EmptyContainer> :
                            <div className="admin-activities-container">
                                {
                                    adminActivities.map((adminActivity) => {
                                        const dateTime = moment(adminActivity["date_created"], "YYYY-MM-DD h:mm:ss");
                                        // const timestamp = dateTime.format('ddd MMM Do, h:mm A');

                                        return(
                                            <div key={adminActivity["id"]} className="mt-3">
                                                <div className="d-flex flex-row justify-content-between">
                                                    <p style={{margin:0}}>{adminActivity["description"]}</p>
                                                    <span style={{fontSize:12}}>{dateTime.fromNow()}</span>
                                                </div>
                                                {
                                                    adminActivity["sub_description"] === "" ? null :
                                                        <div  className="d-flex flex-row justify-content-between align-items-center" style={{marginLeft:20, border:"1px dashed #AAB7B8",
                                                            padding:"10px", borderRadius:4, marginTop:5}}>
                                                            <div className="d-flex flex-row align-items-center">
                                                                {adminActivity["extras"] === "" ?
                                                                    (<span className="shipment-class"><BoxIcons.BiPackage className="icon" /></span>) :
                                                                    <img style={{width:32, height:32}} src={adminActivity["extras"]}  alt=""/>
                                                                }
                                                                <span>{adminActivity["sub_description"]}</span>
                                                            </div>
                                                            {
                                                                adminActivity["button_text"] === "" ? null :
                                                                    <span onClick={(e) => goToPage(adminActivity["type"], adminActivity["type_id"])} style={{color: "#37458b", backgroundColor: "#dee3fa", border:"1px solid #ced5f8",
                                                                        padding: "5px 15px 5px 15px", borderRadius: "3px",fontSize: "14px", cursor:"pointer"}}>
                                                        {adminActivity["button_text"]}
                                                </span>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
                <div className={`${activeTab === "AdminPrivileges" ? "show-customer-tab" : "hide-customer-tab"}`}>
                    {
                        isError === false ? null :
                            (<ErrorMessageContainer>
                                {errorMessage}
                            </ErrorMessageContainer>)
                    }

                    {
                        isSuccess=== false ? null :
                            (<SuccessMessageContainer>
                                {successMessage}
                            </SuccessMessageContainer>)
                    }
                    <form ref={form}>
                        <Row className="form-row mt-4">
                            <Col className="form-group col-xl-6 col-md-6">
                                <span style={{fontWeight:"bold"}}>Shipment Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Create Shipment</label>
                                        <Switch defaultValue="0" name="create_shipment" onChange={() => setCreateShipment(!createShipment)} checked={createShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Approve Shipment</label>
                                        <Switch name="approve_shipment" onChange={() => setApproveShipment(!approveShipment)} checked={approveShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Route Shipment</label>
                                        <Switch name="route_shipment" onChange={() => setRouteShipment(!routeShipment)} checked={routeShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reroute Shipment</label>
                                        <Switch name="reroute_shipment" onChange={() => setRerouteShipment(!rerouteShipment)} checked={rerouteShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Cancel Shipment</label>
                                        <Switch name="cancel_shipment" onChange={() => setCancelShipment(!cancelShipment)} checked={cancelShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reject Shipment</label>
                                        <Switch name="reject_shipment" onChange={() => setRejectShipment(!rejectShipment)} checked={rejectShipment} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Transfer Shipment</label>
                                        <Switch name="transfer_shipment" onChange={() => setTransferShipment(!transferShipment)} checked={transferShipment} className="react-switch" />
                                    </div>
                                </div>
                            </Col>

                            <Col className="form-group col-xl-6 col-md-6">
                                <span style={{fontWeight:"bold"}}>Payment Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Approve Wallet Topup</label>
                                        <Switch name="approve_wallet_topup" onChange={() => setApproveWalletTopup(!approveWalletTopup)} checked={approveWalletTopup} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Mark Shipment As Paid</label>
                                        <Switch name="mark_payment_as_paid" onChange={() => setMarkPaymentAsPaid(!markPaymentAsPaid)} checked={markPaymentAsPaid} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Mark Shipment As Unpaid</label>
                                        <Switch name="mark_payment_as_unpaid" onChange={() => setMarkPaymentAsUnpaid(!markPaymentAsUnpaid)} checked={markPaymentAsUnpaid} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Fund Customer Wallet</label>
                                        <Switch name="fund_user_wallet" onChange={() => setFundUserWallet(!fundUserWallet)} checked={fundUserWallet} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Deduct Customer Wallet</label>
                                        <Switch name="deduct_user_wallet" onChange={() => setDeductUserWallet(!deductUserWallet)} checked={deductUserWallet} className="react-switch" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="form-group col-xl-6 col-md-6">
                                <span style={{fontWeight:"bold"}}>Customer Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Send Push Notifications</label>
                                        <Switch name="send_push_notifications" onChange={() => setSendPushNotifications(!sendPushNotifications)} checked={sendPushNotifications} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Send Email</label>
                                        <Switch name="send_email" onChange={() => setSendEmail(!sendEmail)} checked={sendEmail} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Send SMS</label>
                                        <Switch name="send_sms" onChange={() => setSendSMS(!sendSMS)} checked={sendSMS} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Make Contract Customer</label>
                                        <Switch name="make_contract_user" onChange={() => setMakeContractUser(!makeContractUser)} checked={makeContractUser} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Make Regular Customer</label>
                                        <Switch name="make_regular_user" onChange={() => setMakeRegularUser(!makeRegularUser)} checked={makeRegularUser} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reply Messages</label>
                                        <Switch name="reply_messages" onChange={() => setReplyMessages(!replyMessages)} checked={replyMessages} className="react-switch" />
                                    </div>
                                </div>
                            </Col>

                            <Col className="form-group col-xl-6 col-md-6">
                                <span style={{fontWeight:"bold"}}>Transporter Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Create Transporter</label>
                                        <Switch name="create_rider" onChange={() => setCreateTransporter(!createTransporter)} checked={createTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Change Transporter Details</label>
                                        <Switch name="change_rider_details" onChange={() => setChangeTransporterDetails(!changeTransporterDetails)} checked={changeTransporterDetails} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Deactivate Transporter</label>
                                        <Switch name="deactivate_rider" onChange={() => setDeactivateTransporter(!deactivateTransporter)} checked={deactivateTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Reactivate Transporter</label>
                                        <Switch name="reactivate_rider" onChange={() => setReactivateTransporter(!reactivateTransporter)} checked={reactivateTransporter} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Delete Transporter</label>
                                        <Switch name="delete_rider" onChange={() => setDeleteTransporter(!deleteTransporter)} checked={deleteTransporter} className="react-switch" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col className="form-group col-xl-6 col-md-6">
                                <span style={{fontWeight:"bold"}}>Fund Management</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Transfer Funds</label>
                                        <Switch name="transfer_funds" onChange={() => setTransferFunds(!transferFunds)} checked={transferFunds} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Buy Airtime</label>
                                        <Switch name="buy_airtime" onChange={() => setBuyAirtime(!buyAirtime)} checked={buyAirtime} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Buy Electricity</label>
                                        <Switch onChange={() => setBuyElectricity(!buyElectricity)} checked={buyElectricity} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Buy Internet</label>
                                        <Switch name="buy_internet" onChange={() => setBuyInternet(!buyInternet)} checked={buyInternet} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Buy Toll</label>
                                        <Switch name="buy_toll" onChange={() => setBuyToll(!buyToll)} checked={buyToll} className="react-switch" />
                                    </div>
                                </div>
                            </Col>

                            <Col className="form-group col-xl-6 col-md-6">
                                <span style={{fontWeight:"bold"}}>Admin Privileges</span>
                                <div style={{borderRadius:6, border:"1px solid #D5DBDB",  paddingLeft:10, backgroundColor:"white"}}>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Create New Admin</label>
                                        <Switch name="create_employee" onChange={() => setCreateAdmin(!createAdmin)} checked={createAdmin} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Edit Admin Details</label>
                                        <Switch name="edit_employee" onChange={() => setEditAdmin(!editAdmin)} checked={editAdmin} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Suspend Admin</label>
                                        <Switch name="suspend_employee" onChange={() => setSuspendAdmin(!suspendAdmin)} checked={suspendAdmin} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Unsuspend Admin</label>
                                        <Switch name="unsuspend_employee" onChange={() => setUnsuspendAdmin(!unsuspendAdmin)} checked={unsuspendAdmin} className="react-switch" />
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <label htmlFor="Full Name">Delete Admin</label>
                                        <Switch name="delete_employee" onChange={() => setDeleteAdmin(!deleteAdmin)} checked={deleteAdmin} className="react-switch" />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="d-flex flex-row my-5 justify-content-end">
                            <SubmitButton onClick={(e)=> updateAdmin()} type="button" className="confirm-button">
                                {isUpdatingAdmin === false ? (
                                    <span className="button-text">UPDATE PRIVILEGES</span>
                                ) : (
                                    <span className="spinner-border spinner-grow-sm"/>
                                )}
                            </SubmitButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default TransporterActivities;