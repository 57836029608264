import "../styles/home.scss";
import "../styles/customers.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {UserProfileData} from "../library/constants";
import axios from "axios";
import {getCustomersURL, getShipmentsURL, searchForCustomersURL} from "../library/URLs";
import styled from "styled-components";
import * as React from "react";
import {BsDot} from "react-icons/bs";
import {map} from "react-bootstrap/ElementChildren";
import {get520PartnersURL} from "../library/Five20URLs";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Five20Partners = () => {
    const form = useRef(null);
    const navigator = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState([]);

    const [isQuerySearching, setIsQuerySearching] = useState(false);
    const [resultContainerVisibility, setResultContainerVisibility] = useState(false);
    const [customerQueryResults, setCustomerQueryResults] = useState([]);

    const data = {
        limit: 20,
        offset: 0,
        status: "All",
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(get520PartnersURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const customers = response["data"];

                setCustomers(customers);
            });
    }, []);

    const handleClick = (customerID) => {
        navigator("/partner/" + customerID);
    };

    const searchForCustomers = (e) => {
        e.preventDefault();
    }

    const handleQueryChange = () => {
        const formData = new FormData(form.current);
        const query = formData.get("query");

        if (query.toString().trim() === ""){
            setIsQuerySearching(false);
            setResultContainerVisibility(false);

            return null;
        }

        const formValues = {}
        formValues["query"] = query;
        formValues["limit"] = "5";

        const newData = { ...formValues, ...UserProfileData() };

        setIsQuerySearching(true);
        setResultContainerVisibility(true);

        axios
            .post(searchForCustomersURL, newData)
            .then(function (response){
                console.log(response);

                if (response["data"]["success"] === false) return;

                const customers = response["data"]["data"];

                setCustomerQueryResults(customers);
            });
    }

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="d-flex flex-row justify-content-between mb-2 mx-3">
            <h4>Partners</h4>
            <span>Home</span>
          </div>
            <div className="container-fluid shipments-container">
                <div className="card bg-light border-0 shadow p-4 min-vh-100">
                    <div className="d-flex justify-content-end mb-3">
                        <form className="query-result-container" style={{width:"50%"}} ref={form} onSubmit={searchForCustomers}>
                            <input onChange={handleQueryChange} name="query" className="form-control" type="text" placeholder="Search using customer's name or number"/>
                            <div className={`query-result ${resultContainerVisibility === true ? "result-visible" : null}`}>
                                {setIsQuerySearching === true ? null :
                                    customerQueryResults.map((customer) => {
                                        return(
                                            <div key={customer.id} className="d-flex flex-column" onClick={() => handleClick(customer.id)}>
                                                <span style={{fontSize:15}}>{customer.fullname}</span>
                                                <span className="d-flex align-items-center">
                                                    <span style={{fontSize:13}}>{customer.phone}</span>
                                                    <BsDot/>
                                                    <span style={{fontSize:13}}>{customer.email}</span>
                                                </span>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </form>
                    </div>
                    <table className="shipments-table">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Member Type</th>
                        </tr>
                        </thead>
                        {isLoading === true ? null : (
                            <tbody>
                            {customers.map((customer) => {
                                return (
                                    <tr key={customer.id} onClick={() => handleClick(customer.id)}>
                                        <td>{customer.first_name + " " + customer.last_name}</td>
                                        <td>{customer.email}</td>
                                        <td>{customer.phone}</td>
                                        <td>{customer.member_status}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        )}
                    </table>
                    {isLoading === false ? null : (
                        <LoadingContainer>
                            <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                        </LoadingContainer>
                    )}
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Five20Partners;
