import ReactApexChart from "react-apexcharts";

const DashboardGraph = ({label, shipmentsData, registeredUsersData}) => {
  const series = [
    {
      name: "Shipments",
      data: shipmentsData,
    },
    // {
    //   name: "Registered Users",
    //   data: [11, 32, 45, 32, 34, 52, 41],
    // },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "string",
      categories: label,
    },
    // tooltip: {
    //   x: {
    //     format: "dd/MM/yy HH:mm",
    //   },
    // },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={350}
    />
  );
};

export default DashboardGraph;
