import {Button, Col, Modal, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {
    createInternationalShipmentURL, deductUserWalletURL,
    fundUserWalletURL,
    getContractDetailsUserURL,
    makeContractUserURL, topupUserWalletURL
} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {BiX} from "react-icons/bi";
import * as React from "react";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;


const DeductUserWalletDialog = ({ isModalVisible, handleCloseDialog, customerID, phoneNumber, customerName }) => {
    const form = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const deductUserWallet = () => {
        const formValues = {};
        formValues['customer_id'] = customerID;

        const data = new FormData(form.current);
        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        const newData = { ...formValues, ...UserProfileData() };

        console.log(newData);

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(deductUserWalletURL, newData)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    window.scrollTo(0, 0);
                    setErrorVisibility(true);
                    setErrorMessage(response["data"]["message"]);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("User wallet has been successfully deducted");

                    setTimeout(() => {
                        setSuccessVisibility(false);
                        handleCloseDialog("Refresh");
                    }, 3000);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                return error;
            });
    }

    return (
        <Modal show={isModalVisible} onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 style={{marginTop:10}} className="header">DEDUCT USER WALLET</h5>
                <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
            </Modal.Header>
            <Modal.Body>
                {
                    isError === false ? null :
                        (<ErrorMessageContainer>
                            {errorMessage}
                        </ErrorMessageContainer>)
                }

                {
                    isSuccess=== false ? null :
                        (<SuccessMessageContainer>
                            {successMessage}
                        </SuccessMessageContainer>)
                }

                <form ref={form}>
                    <div>
                        <Row className="mt-3">
                            <Col>
                                <label htmlFor="User Name">User Name</label>
                                <input name="name" className="form-control" type="text" defaultValue={customerName} disabled={true} />
                            </Col>
                            <Col>
                                <label htmlFor="User Phone Number">User Phone Number</label>
                                <input type="hidden" name="phone_number" value={phoneNumber}/>
                                <input className="form-control" type="text" defaultValue={phoneNumber} disabled={true} />
                            </Col>
                        </Row>

                        <div className="mt-2">
                            <label htmlFor="Amount">Amount</label>
                            <input name="amount" className="form-control" type="text" />
                        </div>

                        <div className="mt-2">
                            <Col>
                                <label htmlFor="Amount">Reason</label>
                                <textarea name="reason" className="form-control" />
                            </Col>
                        </div>
                    </div>
                </form>

            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button mx-3" onClick={(e) => handleCloseDialog()}>
                    CANCEL
                </button>
                <SubmitButton onClick={(e) => deductUserWallet()} type="button" className="confirm-button">
                    {isSubmitting === false ? (
                        <span className="button-text">DEDUCT USER WALLET</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm"/>
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default DeductUserWalletDialog;
