import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
  editAdminPrivilegesURL,
  getAdminActivitiesURL,
  getAdminPrivilegesURL,
} from "../../../library/URLs";
import styled from "styled-components";
import * as BoxIcons from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import { Switch } from "@mui/material";
import moment from "moment";

const LoadingContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EmptyContainer = styled.div`
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #fcd2d2;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #c2eddd;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: center;
`;

const AdminActivities = ({ adminID, adminPrivileges }) => {
  const navigator = useNavigate();

  const form = useRef(null);

  const [isUpdatingAdmin, setIsUpdatingAdmin] = useState(false);

  const [activeTab, setActiveTab] = useState("AdminActivities");

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [adminActivities, setAdminActivities] = useState([]);

  const [employeePrivileges, setEmployeePrivileges] = useState({
    create_employee: "0",
    edit_employee: "0",
    suspend_employee: "0",
    unsuspend_employee: "0",
    create_shipment: "0",
    delete_employee: "0",
    approve_shipment: "0",
    route_shipment: "0",
    reroute_shipment: "0",
    cancel_shipment: "0",
    reject_shipment: "0",
    transfer_shipment: "0",
    reply_messages: "0",
    create_rider: "0",
    deactivate_rider: "0",
    reactivate_rider: "0",
    change_rider_details: "0",
    delete_rider: "0",
    approve_wallet_topup: "0",
    send_push_notifications: "0",
    send_sms: "0",
    send_email: "0",
    mark_payment_as_paid: "0",
    mark_payment_as_unpaid: "0",
    fund_user_wallet: "0",
    deduct_user_wallet: "0",
    make_contract_user: "0",
    make_regular_user: "0",
    create_discount: "0",
    edit_discount: "0",
    cancel_discount: "0",
    transfer_funds: "0",
    buy_airtime: "0",
    buy_electricity: "0",
    buy_internet: "0",
    buy_toll: "0",
    change_app_details: "0",
  });

  const data = {
    employee_id: adminID,
    limit: 20,
    offset: 0,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  useEffect(() => {
    axios
      .post(getAdminActivitiesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const adminActivities = response["data"]["data"];

        console.log(adminActivities.length);

        if (adminActivities.length === 0) setIsEmpty(true);

        setAdminActivities(adminActivities);
      });
  }, []);

  useEffect(() => {
    axios
      .post(getAdminPrivilegesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);

        if (response["data"]["success"] === false) return;

        const {
          company_id,
          user_id,
          id,
          first_name,
          last_name,
          station,
          employee_type,
          ...privileges
        } = response["data"]["data"];

        setEmployeePrivileges(privileges);
      });
      // eslint-disable-next-line
  }, []);

  const updateAdmin = () => {
    const formValues = {};
    formValues["privileges"] = { ...employeePrivileges };
    formValues["employee_id"] = adminID;

    const newData = { ...formValues, ...UserProfileData() };

    setIsUpdatingAdmin(true);
    axios
      .post(editAdminPrivilegesURL, newData)
      .then((response) => {
        console.log(response);
        setIsUpdatingAdmin(false);
        window.scrollTo(0, 0);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Successfully updated admin.");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
        } else {
          const message = response["data"]["message"];

          setErrorVisibility(true);
          setErrorMessage(message);
          setTimeout(() => {
            setErrorVisibility(false);
          }, 3000);
        }
      })
      .catch((error) => console.log(error));
  };

  const goToPage = (type, typeID) => {
    if (type === "Shipment") {
      navigator("/shipment/" + typeID);
    } else if (type === "Customer") {
      navigator("/customer/" + typeID);
    } else if (type === "Transporter") {
      navigator("/transporter/" + typeID);
    }
  };

  const toggleEmployee = (e) => {
    const { name } = e.target;

    if (employeePrivileges[name] === "0") {
      setEmployeePrivileges({
        ...employeePrivileges,
        [name]: "1",
      });
    } else {
      setEmployeePrivileges({
        ...employeePrivileges,
        [name]: "0",
      });
    }
  };

  return (
    <div className="card bg-light border-0 shadow">
      <div className="card-body">
        <div>
          <span
            onClick={(e) => setActiveTab("AdminActivities")}
            className={`profile-tab ${
              activeTab === "AdminActivities" ? "active-profile" : null
            }`}
          >
            Activities
          </span>
          {adminPrivileges["edit_employee"] === "1" ? (
            <span
              onClick={(e) => setActiveTab("AdminPrivileges")}
              className={`profile-tab ${
                activeTab === "AdminPrivileges" ? "active-profile" : null
              }`}
            >
              Privileges
            </span>
          ) : null}
        </div>

        <div
          className={`${
            activeTab === "AdminActivities"
              ? "show-customer-tab"
              : "hide-customer-tab"
          }`}
        >
          {adminActivities.length === 0 ? (
            <EmptyContainer>
              Admin has no recorded activities yet...
            </EmptyContainer>
          ) : (
            <div className="admin-activities-container">
              {adminActivities.map((adminActivity) => {
                const dateTime = moment(
                  adminActivity["date_created"],
                  "YYYY-MM-DD h:mm:ss"
                );
                // const timestamp = dateTime.format('ddd MMM Do, h:mm A');

                return (
                  <div key={adminActivity["id"]} className="mt-3">
                    <div className="d-flex flex-row justify-content-between">
                      <p style={{ margin: 0 }}>
                        {adminActivity["description"]}
                      </p>
                      <span style={{ fontSize: 12 }}>{dateTime.fromNow()}</span>
                    </div>
                    {adminActivity["sub_description"] === "" ? null : (
                      <div
                        className="d-flex flex-row justify-content-between align-items-center"
                        style={{
                          marginLeft: 20,
                          border: "1px dashed #AAB7B8",
                          padding: "10px",
                          borderRadius: 4,
                          marginTop: 5,
                        }}
                      >
                        <div className="d-flex flex-row align-items-center">
                          {adminActivity["extras"] === "" ? (
                            <span className="shipment-class">
                              <BoxIcons.BiPackage className="icon" />
                            </span>
                          ) : (
                            <img
                              style={{ width: 32, height: 32 }}
                              src={adminActivity["extras"]}
                              alt=""
                            />
                          )}
                          <span>{adminActivity["sub_description"]}</span>
                        </div>
                        {adminActivity["button_text"] === "" ? null : (
                          <span
                            onClick={(e) =>
                              goToPage(
                                adminActivity["type"],
                                adminActivity["type_id"]
                              )
                            }
                            style={{
                              color: "#37458b",
                              backgroundColor: "#dee3fa",
                              border: "1px solid #ced5f8",
                              padding: "5px 15px 5px 15px",
                              borderRadius: "3px",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                          >
                            {adminActivity["button_text"]}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className={`${
            activeTab === "AdminPrivileges"
              ? "show-customer-tab"
              : "hide-customer-tab"
          }`}
        >
          {isError === false ? null : (
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          )}

          {isSuccess === false ? null : (
            <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
          )}
          <form ref={form}>
            <Row className="form-row mt-4">
              <Col className="form-group col-xl-6 col-md-6">
                <span style={{ fontWeight: "bold" }}>Shipment Privileges</span>
                <div
                  style={{
                    borderRadius: 6,
                    border: "1px solid #D5DBDB",
                    paddingLeft: 10,
                    backgroundColor: "white",
                  }}
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Create Shipment</label>
                    <Switch
                      defaultValue="0"
                      name="create_shipment"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.create_shipment === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Approve Shipment</label>
                    <Switch
                      name="approve_shipment"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.approve_shipment === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Route Shipment</label>
                    <Switch
                      name="route_shipment"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.route_shipment === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Reroute Shipment</label>
                    <Switch
                      name="reroute_shipment"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.reroute_shipment === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Cancel Shipment</label>
                    <Switch
                      name="cancel_shipment"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.cancel_shipment === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Reject Shipment</label>
                    <Switch
                      name="reject_shipment"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.reject_shipment === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Transfer Shipment</label>
                    <Switch
                      name="transfer_shipment"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.transfer_shipment === "1"}
                      className="react-switch"
                    />
                  </div>
                </div>
              </Col>

              <Col className="form-group col-xl-6 col-md-6">
                <span style={{ fontWeight: "bold" }}>Payment Privileges</span>
                <div
                  style={{
                    borderRadius: 6,
                    border: "1px solid #D5DBDB",
                    paddingLeft: 10,
                    backgroundColor: "white",
                  }}
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Approve Wallet Topup</label>
                    <Switch
                      name="approve_wallet_topup"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.approve_wallet_topup === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Mark Shipment As Paid</label>
                    <Switch
                      name="mark_payment_as_paid"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.mark_payment_as_paid === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Mark Shipment As Unpaid</label>
                    <Switch
                      name="mark_payment_as_unpaid"
                      onChange={toggleEmployee}
                      checked={
                        employeePrivileges.mark_payment_as_unpaid === "1"
                      }
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Fund Customer Wallet</label>
                    <Switch
                      name="fund_user_wallet"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.fund_user_wallet === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Deduct Customer Wallet</label>
                    <Switch
                      name="deduct_user_wallet"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.deduct_user_wallet === "1"}
                      className="react-switch"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="form-group col-xl-6 col-md-6">
                <span style={{ fontWeight: "bold" }}>Customer Privileges</span>
                <div
                  style={{
                    borderRadius: 6,
                    border: "1px solid #D5DBDB",
                    paddingLeft: 10,
                    backgroundColor: "white",
                  }}
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Send Push Notifications</label>
                    <Switch
                      name="send_push_notifications"
                      onChange={toggleEmployee}
                      checked={
                        employeePrivileges.send_push_notifications === "1"
                      }
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Send Email</label>
                    <Switch
                      name="send_email"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.send_email === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Send SMS</label>
                    <Switch
                      name="send_sms"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.send_sms === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Make Contract Customer</label>
                    <Switch
                      name="make_contract_user"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.make_contract_user === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Make Regular Customer</label>
                    <Switch
                      name="make_regular_user"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.make_regular_user === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Reply Messages</label>
                    <Switch
                      name="reply_messages"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.reply_messages === "1"}
                      className="react-switch"
                    />
                  </div>
                </div>
              </Col>

              <Col className="form-group col-xl-6 col-md-6">
                <span style={{ fontWeight: "bold" }}>
                  Transporter Privileges
                </span>
                <div
                  style={{
                    borderRadius: 6,
                    border: "1px solid #D5DBDB",
                    paddingLeft: 10,
                    backgroundColor: "white",
                  }}
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Create Transporter</label>
                    <Switch
                      name="create_rider"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.create_rider === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">
                      Change Transporter Details
                    </label>
                    <Switch
                      name="change_rider_details"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.change_rider_details === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Deactivate Transporter</label>
                    <Switch
                      name="deactivate_rider"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.deactivate_rider === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Reactivate Transporter</label>
                    <Switch
                      name="reactivate_rider"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.reactivate_rider === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Delete Transporter</label>
                    <Switch
                      name="delete_rider"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.delete_rider === "1"}
                      className="react-switch"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="form-group col-xl-6 col-md-6">
                <span style={{ fontWeight: "bold" }}>Fund Management</span>
                <div
                  style={{
                    borderRadius: 6,
                    border: "1px solid #D5DBDB",
                    paddingLeft: 10,
                    backgroundColor: "white",
                  }}
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Transfer Funds</label>
                    <Switch
                      name="transfer_funds"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.transfer_funds === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Buy Airtime</label>
                    <Switch
                      name="buy_airtime"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.buy_airtime === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Buy Electricity</label>
                    <Switch
                      name="buy_electricity"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.buy_electricity === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Buy Internet</label>
                    <Switch
                      name="buy_internet"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.buy_internet === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Buy Toll</label>
                    <Switch
                      name="buy_toll"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.buy_toll === "1"}
                      className="react-switch"
                    />
                  </div>
                </div>
              </Col>

              <Col className="form-group col-xl-6 col-md-6">
                <span style={{ fontWeight: "bold" }}>Admin Privileges</span>
                <div
                  style={{
                    borderRadius: 6,
                    border: "1px solid #D5DBDB",
                    paddingLeft: 10,
                    backgroundColor: "white",
                  }}
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Create New Admin</label>
                    <Switch
                      name="create_employee"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.create_employee === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Edit Admin Details</label>
                    <Switch
                      name="edit_employee"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.edit_employee === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Suspend Admin</label>
                    <Switch
                      name="suspend_employee"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.suspend_employee === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Unsuspend Admin</label>
                    <Switch
                      name="unsuspend_employee"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.unsuspend_employee === "1"}
                      className="react-switch"
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label htmlFor="Full Name">Delete Admin</label>
                    <Switch
                      name="delete_employee"
                      onChange={toggleEmployee}
                      checked={employeePrivileges.delete_employee === "1"}
                      className="react-switch"
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <div className="d-flex flex-row my-5 justify-content-end">
              <SubmitButton
                onClick={(e) => updateAdmin()}
                type="button"
                className="confirm-button"
              >
                {isUpdatingAdmin === false ? (
                  <span className="button-text">UPDATE PRIVILEGES</span>
                ) : (
                  <span className="spinner-border spinner-grow-sm" />
                )}
              </SubmitButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminActivities;
