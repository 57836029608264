import "../../styles/login.scss";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {generateTransactionID} from "../../library/utilities";
import axios from "axios";
import {loginURL, requestResetCodeURL} from "../../library/URLs";
import {SessionManagement} from "../../library/SessionManagement";
import {ErrorNotification} from "../../components/Notifications";
import * as React from "react";

const PageHeader = styled.h4`
  font-family: Prompt,sans-serif;
  font-size: 55px;
  text-align: center;
  margin-bottom: 10px;
  color: #0b613a;
`;

const PageSubheader = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: normal;
`;

const PageFooter = styled.h4`
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    margin-bottom:20px;
    text-align:center;
`;

const ForgotPassword = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [email, setEmail] = useState("");

    const navigate = useNavigate();
    const form = useRef(null);

    const clearErrorMessage = () => {
        setErrorVisibility(false);
        setErrorMessage("");
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        clearErrorMessage();

        const data = new FormData(form.current);

        const formValues = {};

        for (let [key, value] of data.entries()) {
            formValues[key] = value;
        }

        formValues["device_id"] = "value";
        formValues["device_name"] = "value";
        formValues["token"] = "value";
        formValues["transaction_id"] = generateTransactionID(15);

        setIsSubmitting(true);

        axios
            .post(requestResetCodeURL, formValues)
            .then(function (response) {
                console.log(response);
                setIsSubmitting(false);

                if (response["data"]["success"] === false) {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                } else if (response["data"]["success"] === true) {
                    navigate('/reset-password?email=' + email);
                }
            })
            .catch(function (error) {
                return error;
            });
    };

  return (
    <div className="box-container">
      <PageHeader className="page-header">Password Reset</PageHeader>
        {
            isError === false ? null :
                (<ErrorMessageContainer>
                    {errorMessage}
                </ErrorMessageContainer>)
        }

      <PageSubheader>
        Enter your email and let's help you retrieve your password
      </PageSubheader>
      <form ref={form} onSubmit={handleSubmit}>
        <section>
          <div className="input-container">
            <input onChange={(e) =>setEmail(e.target.value)} value={email} placeholder="Your Email" className="login-input" type="text" name="email"  />
          </div>
        </section>

          <div className="d-flex flex-row my-5 justify-content-end">
              <button type="submit" className="login-button">
                  {isSubmitting === false ? (
                      <span className="button-text">REQUEST CODE</span>
                  ) : (
                      <span className="spinner-border spinner-grow-sm"/>
                  )}
              </button>
          </div>

        <PageSubheader>
          Remember your password? <a href="/login">Login</a>
        </PageSubheader>
      </form>
    </div>
  );
};

export default ForgotPassword;
