import {Col, Modal, Row} from "react-bootstrap";
import {useRef, useState} from "react";
import styled from "styled-components";
import {BiX} from "react-icons/bi";
import * as React from "react";
import ReactDatePicker from "react-datepicker";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const CustomDateRangeDialog = ({ isModalVisible, handleCloseDialog}) => {
    const form = useRef(null);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const confirmShipmentDate = () => {
        const data = new FormData(form.current);

        const startDate = data.get("start_date");
        const endDate = data.get("end_date");

        handleCloseDialog(startDate + "|" + endDate);
    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">CHOOSE DATE</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          <form ref={form} onSubmit={confirmShipmentDate}>
                      <div>
                          <Row className="mt-3">
                              <Col>
                                  <label htmlFor="Sender Postal">Choose Start Date</label>
                                  <ReactDatePicker
                                      name="start_date"
                                      className="form-control"
                                      selected={startDate}
                                      dateFormat="P"
                                      onChange={(date: Date) => setStartDate(date)}
                                  />
                              </Col>
                          </Row>

                          <Row>

                          </Row>
                          <div className="mt-2">
                              <label htmlFor="Payment Plan">Choose End Date</label>
                              <ReactDatePicker
                                  name="end_date"
                                  className="form-control"
                                  selected={endDate}
                                  dateFormat="P"
                                  onChange={(date: Date) => setEndDate(date)}
                              />
                          </div>
                      </div>
              </form>

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={(e) => confirmShipmentDate("hey")} type="submit" className="confirm-button">
              <span className="button-text">CONFIRM DATE</span>
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomDateRangeDialog;
