import axios from "axios";
import { useState } from "react";
import { BsX } from "react-icons/bs";
import { FaFileAlt } from "react-icons/fa";
import styled from "styled-components";
import { uploadRateDocumentUrl } from "../../../library/URLs";
import UploadRateDocumentDialog from "../../../modals/UploadRateDocumentDialog";

const SubmitButton = styled.button`
  width: 250px;
`;

const RateCards = ({
  setErrorVisibility,
  setSuccessMessage,
  setSuccessVisibility,
  setErrorMessage,
}) => {
  const [fileDocs, setFileDocs] = useState({
    international_savers: null,
    international_priority: null,
    international_economy: null,
    international_standard: null,
    international_premium: null,
    interstate_standards: null,
    interstate_premium: null,
    import_standard: null,
    import_premium: null,
    import_economy: null,
  });
  const [filename, setFilename] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const cancelDoc = (name) => {
    setFileDocs({
      ...fileDocs,
      [name]: null,
    });
  };

  // Check if all document fields are null
  const checkDocNull = () => {
    for (const key in fileDocs) {
      if (fileDocs.hasOwnProperty(key) && fileDocs[key] !== null) {
        return false;
      }
    }

    return true;
  };

  const selectFileDoc = (e) => {
    if (!checkDocNull()) {
      flagUploadInWait();
      return;
    }

    const { files, name } = e.target;
    let file = files[0];
    setFileDocs({
      ...fileDocs,
      [name]: file,
    });
    setFilename(file?.name);
  };

  const flagUploadInWait = () => {
    setErrorMessage(
      "Please a file is already selected. Ensure to cancel selected file or upload before selecting another!"
    );
    setErrorVisibility(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      setErrorVisibility(false);
      setErrorMessage("");
    }, 6000);
  };

  const flagEmptyDocs = () => {
    setErrorMessage("Please select a document to upload!");
    setErrorVisibility(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      setErrorVisibility(false);
      setErrorMessage("");
    }, 6000);
  };

  const uploadDocument = () => {
    let formData = new FormData();

    if (fileDocs.international_savers) {
      formData.append("rate_file", fileDocs.international_savers);
      formData.append("rate_type", "savers");
    } else if (fileDocs.international_priority) {
      formData.append("rate_file", fileDocs.international_priority);
      formData.append("rate_type", "priority");
    } else if (fileDocs.international_standard) {
      formData.append("rate_file", fileDocs.international_standard);
      formData.append("rate_type", "standard");
    } else if (fileDocs.international_economy) {
      formData.append("rate_file", fileDocs.international_economy);
      formData.append("rate_type", "economy");
    } else if (fileDocs.international_premium) {
      formData.append("rate_file", fileDocs.international_premium);
      formData.append("rate_type", "premium");
    } else if (fileDocs.interstate_standards) {
      formData.append("rate_file", fileDocs.interstate_standards);
      formData.append("rate_type", "interstate-standard");
    } else if (fileDocs.interstate_premium) {
      formData.append("rate_file", fileDocs.interstate_premium);
      formData.append("rate_type", "interstate-premium");
    } else if (fileDocs.import_standard) {
      formData.append("rate_file", fileDocs.import_standard);
      formData.append("rate_type", "import-standard");
    } else if (fileDocs.import_premium) {
      formData.append("rate_file", fileDocs.import_premium);
      formData.append("rate_type", "import-premium");
    } else if (fileDocs.import_economy) {
      formData.append("rate_file", fileDocs.import_economy);
      formData.append("rate_type", "import-economy");
    }

    setIsPending(true);

    axios
      .post(uploadRateDocumentUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setIsPending(false);
        setFileDocs({
          international_savers: null,
          international_economy: null,
          international_priority: null,
          international_standard: null,
          international_premium: null,
          interstate_standards: null,
          interstate_premium: null,
          import_standard: null,
          import_premium: null,
          import_economy: null,
        });

        setSuccessMessage("Document Uploaded.");
        setSuccessVisibility(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setTimeout(() => {
          setSuccessVisibility(false);
          setSuccessMessage("");
          window.location.reload();
        }, 3000);
      })
      .catch(function (error) {
        setIsPending(false);

        setErrorMessage("An error occured");
        setErrorVisibility(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        setTimeout(() => {
          setErrorVisibility(false);
          setErrorMessage("");
        }, 6000);
        return;
      });
  };

  const showSampleDoc = (name) => {
    if (name === "international_savers") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-savers.xlsx",
        "_blank"
      );
    } else if (name === "international_priority") {
      window.open(
        // "https://admin.routely.co/backend/engine/v1/actions/samples/parcelflow-rates.xlsx",
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-savers.xlsx",
        "_blank"
      );
    } else if (name === "international_standard") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-savers.xlsx",
        "_blank"
      );
    } else if (name === "international_economy") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-savers.xlsx",
        "_blank"
      );
    } else if (name === "international_premium") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-premium.xlsx",
        "_blank"
      );
    } else if (name === "interstate_standards") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-interstate-standard.xlsx",
        "_blank"
      );
    } else if (name === "interstate_premium") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-interstate-premium.xlsx",
        "_blank"
      );
    } else if (name === "import_standard") {
      window.open(
        // "https://admin.routely.co/backend/engine/v1/actions/samples/routely-import-standard.xlsx",
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-savers.xlsx",
        "_blank"
      );
    } else if (name === "import_premium") {
      window.open(
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-import-premium.xlsx",
        "_blank"
      );
    } else if (name === "import_economy") {
      window.open(
        // "https://admin.routely.co/backend/engine/v1/actions/samples/routely-import-economy.xlsx",
        "https://admin.routely.co/backend/engine/v1/actions/samples/routely-savers.xlsx",
        "_blank"
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkDocNull()) {
      flagEmptyDocs();
      return;
    }

    openShowModal();
  };

  const openShowModal = () => {
    setShowModal(true);
  };

  const closeShowModal = () => {
    setShowModal(false);
  };

  return (
    <div className="pt-5" >
      <UploadRateDocumentDialog
        isModalVisible={showModal}
        handleCloseDialog={closeShowModal}
        submit={uploadDocument}
        isSubmitting={isPending}
      />
      <p
        style={{
          fontSize: 22,
          marginTop: 30,
          marginBottom: 0,
          fontWeight: "bold",
        }}
      >
        Rate Cards
      </p>
      <span>Upload rate spreadsheets to update shipment rates.</span>
      <div className="row mt-4 rate-sections">
        <div
          className={`rate-card ${
            fileDocs?.international_savers && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.international_savers && (
              <BsX
                onClick={() => cancelDoc("international_savers")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Export Savers</p>
              <p className="rate-desc">
                Upload document to update export (Express Savers).
              </p>
              {fileDocs?.international_savers && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("international_savers")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="international_savers"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.international_priority && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.international_priority && (
              <BsX
                onClick={() => cancelDoc("international_priority")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Export Priority</p>
              <p className="rate-desc">
                Upload document to update export (Express Priority).
              </p>
              {fileDocs?.international_priority && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("international_priority")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="international_priority"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.international_standard && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.international_standard && (
              <BsX
                onClick={() => cancelDoc("international_standard")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Export Standard</p>
              <p className="rate-desc">
                Upload document to update export (Express Standard).
              </p>
              {fileDocs?.international_standard && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("international_standard")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="international_standard"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.international_economy && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.international_economy && (
              <BsX
                onClick={() => cancelDoc("international_economy")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Export Economy</p>
              <p className="rate-desc">
                Upload document to update export (Express Economy).
              </p>
              {fileDocs?.international_economy && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("international_economy")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="international_economy"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.international_premium && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.international_premium && (
              <BsX
                onClick={() => cancelDoc("international_premium")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Export Premium</p>
              <p className="rate-desc">
                Upload document to update export (Express Premium).
              </p>
              {fileDocs?.international_premium && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("international_premium")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="international_premium"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.interstate_standards && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.interstate_standards && (
              <BsX
                onClick={() => cancelDoc("interstate_standards")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Interstate Standard</p>
              <p className="rate-desc">
                Upload document to update Interstate (Express Standard).
              </p>
              {fileDocs?.interstate_standards && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("interstate_standards")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="interstate_standards"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.interstate_premium && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.interstate_premium && (
              <BsX
                onClick={() => cancelDoc("interstate_premium")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Interstate Premium</p>
              <p className="rate-desc">
                Upload document to update Interstate (Express Premium).
              </p>
              {fileDocs?.interstate_premium && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("interstate_premium")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="interstate_premium"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.import_standard && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.import_standard && (
              <BsX
                onClick={() => cancelDoc("import_standard")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Import Standard</p>
              <p className="rate-desc">
                Upload document to update Import (Express Standard).
              </p>
              {fileDocs?.import_standard && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("import_standard")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="import_standard"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.import_premium && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.import_premium && (
              <BsX
                onClick={() => cancelDoc("import_premium")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Import Premium</p>
              <p className="rate-desc">
                Upload document to update Import (Express Premium).
              </p>
              {fileDocs?.import_premium && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("import_premium")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="import_premium"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>

        <div
          className={`rate-card ${
            fileDocs?.import_economy && "rate-card-active"
          }`}
        >
          <div className="rate-left">
            {fileDocs?.import_economy && (
              <BsX
                onClick={() => cancelDoc("import_economy")}
                className="cancel-doc"
              />
            )}
            <div>
              <p className="rate-title">Import Economy</p>
              <p className="rate-desc">
                Upload document to update Import (Express Economy).
              </p>
              {fileDocs?.import_economy && (
                <p className="rate-desc">{filename}</p>
              )}
            </div>
            <p
              className="sample-btn"
              onClick={() => showSampleDoc("import_economy")}
            >
              View Sample
            </p>
          </div>
          <div className="rate-right">
            <FaFileAlt className="rate-card-icon" />
          </div>
          <input
            type="file"
            name="import_economy"
            accept=".xlsx"
            className="rate-input"
            onChange={selectFileDoc}
          />
        </div>
      </div>
      <div className="d-flex flex-row my-5 justify-content-start">
        <SubmitButton
          type="button"
          className="confirm-button"
          onClick={handleSubmit}
        >
          {isPending === false ? (
            <span className="button-text">UPLOAD RATE</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </div>
    </div>
  );
};

export default RateCards;
