import { Modal } from "react-bootstrap";
import axios from "axios";
import { updateCashbackStatusUrl } from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const UpdateCashbackStatusDialog = ({
  isModalVisible,
  handleCloseDialog,
  cashback,
  getCashbacks,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const updateCashbackStatus = (e) => {
    const formValues = {
      cashback_id: cashback?.id,
      status: cashback?.status,
    };

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    console.log(newData);

    axios
      .post(updateCashbackStatusUrl, newData)
      .then(function (response) {
        console.log(response);
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage(
            `Cashback successfully ${
              cashback?.status === "Active" ? "activated" : "deactivated"
            }!`
          );

          setTimeout(() => {
            setSuccessVisibility(false);
            handleCloseDialog();
            getCashbacks();
          }, 3000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">
          {cashback?.status === "Active" ? "ACTIVATE" : "DEACTIVATE"} CASHBACK
        </h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>
          You are about to{" "}
          {`${cashback?.status === "Active" ? "activate" : "deactivate"}`} this
          cashback
        </p>
        <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="cancel-button mx-3"
          onClick={(e) => handleCloseDialog(false)}
        >
          CANCEL
        </button>
        <SubmitButton
          onClick={updateCashbackStatus}
          type="submit"
          className="confirm-button"
        >
          {isSubmitting === false ? (
            <span className="button-text">PROCEED</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateCashbackStatusDialog;
