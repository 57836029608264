import {Button, Col, Modal, Row} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {
    createInternationalShipmentURL,
    fundUserWalletURL,
    getContractDetailsUserURL, getShipmentPricingURL, getShipmentsURL,
    makeContractUserURL, topupUserWalletURL
} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {HiOutlineDotsVertical} from "react-icons/hi";
import {BiX} from "react-icons/bi";
import * as React from "react";
import {SessionManagement} from "../library/SessionManagement";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const ContentFieldsActionButton = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #ffffff;
  display: flex;
  font-size: 30px;
  margin-right:15px;
  align-items: center;
  justify-content: center;
  background-color: #008561;
  cursor:pointer;
  padding-left:2px
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const MotorbikePriceSettingsDialog = ({ isModalVisible, handleCloseDialog, shipmentPricing}) => {
    const [shipmentDeliveryType, setShipmentDeliveryType] = useState("Standard");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [selectedPricingType, setSelectedPricingType] = useState("");

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [contentFields, setContentFields] = useState(1);

    const createContentFields = () => {
        const td = []
        for (let index = 1; index <= contentFields; index++){
            td.push(
                <Row className="form-row mb-3">
                    <Col>
                        <label htmlFor="From">From (KM)</label>
                        <input name={`content_description_${index}`} className="form-control" type="text" placeholder=""/>
                    </Col>

                    <Col>
                        <label htmlFor="To">To (KM)</label>
                        <input name={`content_weight_${index}`} className="form-control" type="text" placeholder=""
                        />
                    </Col>

                    <Col>
                        <label htmlFor="Quantity">Amount</label>
                        <input name={`content_quantity_${index}`} className="form-control" type="text" placeholder=""
                        />
                    </Col>
                </Row>
            );
        }
        return td;
    }

    const handleContentFieldsIncrease = () => {
        if (contentFields < 5){
            setContentFields(contentFields + 1);
        }
    }

    const handleContentFieldsDecrease = () => {
        if (contentFields > 1){
            setContentFields(contentFields - 1);
        }
    }

    const onChangePricingType = event => {
        if (event.target.value === "Base"){
            setSelectedPricingType("Base");
        } else if (event.target.value === "Range"){
            setSelectedPricingType("Range");
        }
    }

    const setMotorbikePricing = () => {

    }

    useEffect(() => {
        axios
            .post(getShipmentPricingURL, UserProfileData())
            .then(function (response) {
                if (response["data"]["success"] === false) {

                } else {
                    if (response["data"].pricing_type === "Range"){
                        setSelectedPricingType("Range");
                        if (Array.isArray(response["data"].data)){
                            let count = 0;
                            (response["data"].data).forEach(function (key, value){
                                if (response["data"].data[value].type === "motorbike" &&
                                    response["data"].data[value].delivery_type === "Standard" &&
                                    response["data"].data[value].status === "active"
                                ){
                                    count++;
                                    console.log(response["data"].data[value].delivery_type);
                                }
                            });

                            setContentFields(count);
                        }
                    } else if (response["data"].pricing_type === "Base"){
                        console.log("Pricing type is base");
                        setSelectedPricingType("Base");
                    } else {
                        console.log("No pricing detected");
                    }
                }
            })
            .catch(function (error) {
                return error;
            });
    }, [])


    console.log(shipmentPricing.pricing_type);

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">SET MOTORBIKE PRICING</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <div>
              {/*<div className="d-flex flex-row justify-content-evenly">*/}
              {/*    <div style={{width: "100%", textAlign: "center", cursor:"pointer"}}><span>Standard Pricing</span></div>*/}
              {/*    <div  style={{width: "100%", textAlign: "center", cursor:"pointer"}}><span>Express Pricing</span></div>*/}
              {/*</div>*/}
              <Row className="mt-2">
                  <Col>
                      <label htmlFor="Sender Postal">Pricing Type</label>
                      <select onChange={onChangePricingType} value={selectedPricingType} name="pricing_type" className="form-select" >
                          <option value="">Choose Pricing Type</option>
                          <option value="Base">Base Pricing</option>
                          <option value="Range">Range Pricing</option>
                      </select>
                  </Col>
              </Row>
              {
                    selectedPricingType === "Base" ?
                  <div>
                      <Row className="mt-3">
                          <Col>
                              <label htmlFor="Base From">Base From (KM)</label>
                              <input name="base_from" className="form-control" type="number" />
                          </Col>
                          <Col>
                              <label htmlFor="Base To">Base To (KM)</label>
                              <input name="base_to" className="form-control" type="number" />
                          </Col>
                      </Row>

                      <Row className="mt-3">
                          <Col>
                              <label htmlFor="Sender Postal">Base Price</label>
                              <input name="base_to" className="form-control" type="number" />
                          </Col>
                      </Row>

                      <Row className="mt-3">
                          <Col>
                              <label htmlFor="Sender Postal">Additional Price Per KM</label>
                              <input name="base_to" className="form-control" type="number" />
                          </Col>
                      </Row>
                  </div> : selectedPricingType === "Range" ?
                  <div className="mt-3">
                      { createContentFields() }
                      <div className="d-flex justify-content-center">
                          <ContentFieldsActionButton onClick={handleContentFieldsDecrease}>-</ContentFieldsActionButton>
                          <ContentFieldsActionButton onClick={handleContentFieldsIncrease}>+</ContentFieldsActionButton>
                      </div>
                  </div> : null
              }
          </div>

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">SET MOTORBIKE PRICING</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default MotorbikePriceSettingsDialog;
