import styled from "styled-components";
import {HiOutlineDotsHorizontal} from "react-icons/hi";
import * as React from "react";
import {useState} from "react";
import MakeRegularUserDialog from "../../modals/MakeRegularUserDialog";
import MarkShipmentAsPaidDialog from "../../modals/MarkShipmentAsPaidDialog";
import {BsCheckAll} from "react-icons/bs";
import { getShipmentPackagesURL } from "../../library/URLs";
import { UserProfileData } from "../../library/constants";
import axios from "axios";
import { IoMdHelpCircle } from "react-icons/io";
import Tooltip from "@mui/material/Tooltip";
import PackageItemsDialog from "../../modals/PackageItemDialog";

const LoadingContainer = styled.div`
  height: 165px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-radius: 5px;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Unpaid = styled.div`
    color: #924040;
    width: 95px;
    background-color: #fde1e1;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid #fcd2d2;
    text-align: center;
`;

const Paid = styled.div`
    width: 95px;
    color: #1f7556;
    background-color: #d6f3e9;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid #c2eddd;
    text-align: center;
`;

const ShipmentInformation = ({isRetrieving, amount, description, paymentMethod, paymentStatus, packageType,
  approvedBy, processor, itemValue, itemValueCurrency, weight, shipmentID, shipmentPlan, insurance, pickupType, 
  packaging, shipmentNumber, requestPickup, serviceStation}) => {

  const [showMarkShipmentAsPaidDialog, setShowMarkShipmentAsPaidDialog] = useState(false);

  const toggleMarkShipmentAsPaidDialog = () => {
    setShowMarkShipmentAsPaidDialog(!showMarkShipmentAsPaidDialog);
    console.log("hello");
  }

  const [showPackages, setShowPackages] = useState(false);
  const [packageItems, setPackageItems] = useState([]);

  const data = {
    shipment_number: shipmentNumber,
  };

  const newData = { ...data, ...UserProfileData() };

  const fetchShipmentPackages = () => {
    console.log("sdfafw", shipmentID);
    axios
      .post(getShipmentPackagesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        console.log("poas", response["data"]);
        setPackageItems(response["data"]);
        setShowPackages(true);
      });
  };

  const handleCloseModal = () => {
    setShowMarkShipmentAsPaidDialog(false);
  };

  return (
    <div className="col-xl-12">

      <MarkShipmentAsPaidDialog
          isModalVisible={showMarkShipmentAsPaidDialog}
          handleCloseDialog={handleCloseModal}
          shipmentID={shipmentID}
      />

      <PackageItemsDialog
        packages={packageItems}
        isModalVisible={showPackages}
        handleCloseDialog={() => setShowPackages(false)}
        shipmentNumber={shipmentNumber}
      />

      <span className="header">Shipment Information</span>
      {
        isRetrieving === true ?
        <LoadingContainer>
          <LoadingSpinner className="spinner-border spinner-grow-sm"/>
        </LoadingContainer> :
            <div className="card bg-light border-0 shadow">
              <div className="card-body shipper-container">
                <div className="row">
                  <div className="col-xl-3">
                    <span className="title">Amount</span>
                    <p>₦{parseFloat(amount).toLocaleString("en")}</p>
                  </div>
                  <div
                className="col-xl-3"
                style={{ cursor: "pointer" }}
                onClick={() => fetchShipmentPackages()}
              >
                <span className="title d-flex gap-1 align-items-start" style={{textDecoration:"underline", color:"#f46a6a"}}>
                  Description
                  <div className="position-relative text-black">
                    <Tooltip
                      title="Click here to view packages"
                      placement="top"
                    >
                      <span>
                        <IoMdHelpCircle />
                      </span>
                    </Tooltip>
                  </div>
                </span>
                <p>{description}</p>
              </div>
                  <div className="col-xl-3">
                    <span className="title">Payment Method</span>
                    <p>{paymentMethod}</p>
                  </div>
                  <div className="col-xl-3">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <span className="title">Payment Status</span>
                      {paymentStatus === "Unpaid" ? <BsCheckAll onClick={toggleMarkShipmentAsPaidDialog} style={{width:20, cursor:"pointer", color:"green"}}/> : null}
                    </div>
                    {paymentStatus === "" ? null : paymentStatus === "Paid" ? <Paid>{paymentStatus}</Paid> : <Unpaid>{paymentStatus}</Unpaid>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3">
                    <span className="title">Item Value</span>
                    <p>{itemValue === null || itemValue === "" ? "Undefined" : itemValueCurrency + " " + parseFloat(itemValue).toLocaleString("en")}</p>
                  </div>
                  <div className="col-xl-3">
                    <span className="title">Weight</span>
                    <p>{weight  === null || weight === "" ? "Undefined" : weight + "KG"}</p>
                  </div>
                  <div className="col-xl-3">
                    <span className="title">Plan</span>
                    <p>{shipmentPlan}</p>
                  </div>
                  <div className="col-xl-3">
                    <span className="title">Approved By</span>
                    <p>{approvedBy}</p>
                  </div>
                </div>
                  <div className="row">
                      <div className="col-xl-3">
                          <span className="title">Insurance</span>
                          <p>{insurance}</p>
                      </div>
                      <div className="col-xl-3">
                          <span className="title">Pickup Type</span>
                          <p>{pickupType}</p>
                      </div>
                      <div className="col-xl-3">
                          <span className="title">Package Type</span>
                          <p>{packageType}</p>
                      </div>
                      <div className="col-xl-3">
                          <span className="title">Packaging</span>
                          <p>{packaging}</p>
                      </div>
                      <div className="col-xl-3">
                          <span className="title">Request Pickup</span>
                          <p>{requestPickup}</p>
                      </div>
                      {
                        serviceStation !== "" && (
                          <div className="col-xl-12">
                              <span className="title">Service Station</span>
                              <p>{serviceStation}</p>
                          </div>
                        )
                      }
                  </div>
              </div>
            </div>
      }
    </div>
  );
};

export default ShipmentInformation;
