import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useState, useEffect, useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import {
    getAllCustomerWalletURL,
    getCustomerWalletHistoryURL,
    getTransactionsURL,
    searchForCustomersURL
} from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import {GoPrimitiveDot} from "react-icons/go";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../components/SubscriptionInactive";
import {BsDot} from "react-icons/bs";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const CustomersWalletHistory = () => {
    const form = useRef(null);
    const navigator = useNavigate();
    const params = useParams();
    const customerID = params["customerID"];

    const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
    const [chosenDate, setChosenDate] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);
    const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] = useState(false);

    const [isQuerySearching, setIsQuerySearching] = useState(false);
    const [resultContainerVisibility, setResultContainerVisibility] = useState(false);
    const [customerQueryResults, setCustomerQueryResults] = useState([]);


    const [isOptionSelected, setIsOptionSelected] = useState("");

    const [transactions, setShipments] = useState([]);

    const data = {
        limit: 20,
        offset: currentOffset,
        customer_id: customerID
    };

    const newData = { ...data, ...UserProfileData() };

    useEffect(() => {
        axios
            .post(getCustomerWalletHistoryURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                if (isLoading === false && isRetrievingMore === false){
                    console.log("Should not retrieve");
                    return;
                }
                console.log(response);
                setIsRetrievingMore(false)
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const newShipments = response["data"]["data"];

                if (newShipments.length > 0){
                    const index = newShipments[newShipments.length - 1].id;

                    setCurrentOffset(index);
                }

                if (currentOffset === 0){
                    setShipments(newShipments);
                } else {
                    setShipments([...transactions, ...newShipments]);
                }
            });
    }, [isRetrievingMore, isOptionSelected]);

    const handleClick = (transactionID) => {
        // navigator("/transaction/" + transactionID);
    };

    const setScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && isRetrievingMore === false){
            setIsRetrievingMore(true);
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", setScroll);
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);

    const exportToCSV = () => {
        if (transactions.length === 0)
            return;

        let csvContent = "data:text/csv;charset=utf-8,";

        csvContent += "Description,Amount,Type,Date"  + "\r\n";

        transactions.forEach(function(shipment) {
            let details = shipment.description.replaceAll(",", "") + "," + shipment.amount
                + "," + shipment.type.replaceAll(",", "") + "," + shipment.date_time.replaceAll(",", "");

            csvContent += details + "\r\n";
        });

        const encodedURI = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedURI)
        link.setAttribute("download", "customer-wallet-history.csv")
        document.body.appendChild(link)

        link.click();
    }

    const onChangePaymentStatus = event => {
        setChosenPaymentStatus(event.target.value)
    }

    const toggleCustomDateRangeDialog= () => {
        setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
    }

    const searchForCustomers = (e) => {
        e.preventDefault();
    }

    const handleQueryChange = () => {
        const formData = new FormData(form.current);
        const query = formData.get("query");

        if (query.toString().trim() === ""){
            setIsQuerySearching(false);
            setResultContainerVisibility(false);

            return null;
        }

        const formValues = {}
        formValues["query"] = query;
        formValues["limit"] = "5";

        const newData = { ...formValues, ...UserProfileData() };

        setIsQuerySearching(true);
        setResultContainerVisibility(true);

        axios
            .post(searchForCustomersURL, newData)
            .then(function (response){
                console.log(response);

                if (response["data"]["success"] === false) return;

                const customers = response["data"]["data"];

                setCustomerQueryResults(customers);
            });
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />
                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5>Customers Wallet History</h5>
                        <span>Home</span>
                    </div>
                    <div className="container-fluid shipments-container">
                        <CustomDateRangeDialog
                            isModalVisible={isCustomDateRangeDialogVisible}
                            handleCloseDialog={(e) => {
                                if (typeof e === "string"){
                                    console.log("The chosen date is " + e.toString());
                                    setChosenDate(e);
                                }
                                toggleCustomDateRangeDialog("close");
                            }}
                        />

                        <div className="card bg-light border-0 shadow p-4 min-vh-100">

                            <div className="d-flex justify-content-between">
                                <div></div>
                                <div className="d-flex align-items-center gap-2">
                                    <input className="confirm-button" type="button" onClick={()=>navigator(`/customer/${customerID}`) } value="VIEW CUSTOMER'S PROFILE" />
                                    <input className="confirm-button" type="button" onClick={exportToCSV} value="EXPORT TO CSV" />
                                </div>

                            </div>

                            <table className="shipments-table">
                                <thead>
                                <tr>
                                    <th scope="col">Description</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Date</th>
                                </tr>
                                </thead>
                                {isLoading === true ? null : (
                                    <tbody>
                                    {transactions.map((history) => {
                                        const dateTime = moment(history.date_time);
                                        const timestamp = dateTime.format('ddd MMM Do, h:mm A');
                                        const color = history.type === "credit" ? "green" : "red";
                                        return (
                                            <tr key={history.id} onClick={() => handleClick(history.id)}>
                                                <td style={{width:"55%"}}>{history.description}</td>
                                                <td>₦{parseFloat(history.amount).toLocaleString("en")}</td>
                                                <td><GoPrimitiveDot style={{color:color}} />{history.type}</td>
                                                <td>{timestamp}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                )}
                            </table>
                            {isRetrievingMore === false ? null :
                                <div style={{textAlign:"center", marginTop:40}}>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/> Retrieving More..
                                </div>
                            }

                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm"/>
                                </LoadingContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomersWalletHistory;
