import "../styles/home.scss";
import "../styles/single-transaction.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import {useNavigate, useParams} from "react-router-dom";
import { useEffect, useState } from "react";
import {getTransactionDetailsURL} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import * as React from "react";
import {ErrorNotification, SuccessNotification} from "../components/Notifications";
import * as BoxIcons from "react-icons/bi";
import SubscriptionInactive from "../components/SubscriptionInactive";


const SingleTransaction= () => {
    const navigator = useNavigate();
    const params = useParams();
    const transactionID = params["transactionID"];

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [amount, setAmount] = useState("");
    const [type, setType] = useState("");
    const [paymentType, setPaymentType] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [network, setNetwork] = useState("");
    const [employeeID, setEmployeeID] = useState("");
    const [employeeName, setEmployeeName] = useState("");
    const [channel, setChannel] = useState("");
    const [bank, setBank] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountName, setAccountName] = useState("");
    const [processorTransactionID, setProcessorTransactionID] = useState("");
    const [processorTransactionReference, setProcessorTransactionReference] = useState("");
    const [timestamp, setTimestamp] = useState("");
    const [status, setStatus] = useState("");
    const [statusClass, setStatusClass] = useState("");
    const [shipmentID, setShipmentID] = useState("");
    const [shipmentDetail, setShipmentDetail] = useState("");

    const [senderBank, setSenderBank] = useState("");
    const [senderAccountNumber, setSenderAccountNumber] = useState("");

    const [recipientBank, setRecipientBank] = useState("");
    const [recipientAccountNumber, setRecipientAccountNumber] = useState("");

    const [activeTab, setActiveTab] = useState("");

    const data = {
        id: transactionID,
    };

    const newData = { ...data, ...UserProfileData() };

    console.log(newData);

    const goToSender = () => {

    }

    const goToShipment = (shipmentID) => {
        navigator("/shipment/" + shipmentID);
    }

    useEffect(() => {
        axios
            .post(getTransactionDetailsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response);
                if (response["data"]["success"] === false) return;

                const transactionDetails = response["data"];

                const accountName = transactionDetails["data"]["account_name"] === "Anonymous Customer" ? transactionDetails["data"]["sender_account_name"] : transactionDetails["data"]["account_name"];
                const accountNumber = transactionDetails["data"]["account_number"];
                const amount = transactionDetails["data"]["amount"];
                const bankName = transactionDetails["data"]["bank_name"];
                const channel = transactionDetails["data"]["channel"];
                const type = transactionDetails["data"]["type"];
                const paymentType = transactionDetails["data"]["payment_type"];
                const timestamp = transactionDetails["data"]["date_time"];
                const status = transactionDetails["data"]["status"];
                const employeeID = transactionDetails["data"]["employee_id"];
                const employeeName = transactionDetails["data"]["employee_name"];
                const processorTransactionReference = transactionDetails["data"]["transaction_reference"];
                const shipmentDetail = transactionDetails["data"]["shipment_detail"];
                const shipmentID = transactionDetails["data"]["shipment_id"];

                const senderBank = transactionDetails["data"]["sender_bank"];
                const senderAccountNumber = transactionDetails["data"]["sender_account_number"];
                const recipientBank = transactionDetails["data"]["recipient_bank_name"];
                const recipientAccountNumber = transactionDetails["data"]["recipient_account_number"];

                const phoneNumber = transactionDetails["data"]["phone_number"];
                const network = transactionDetails["data"]["network"];

                setAccountName(accountName);
                setAccountNumber(accountNumber);
                setAmount(amount);
                setBank(bankName);
                setChannel(channel);
                setType(type);
                setActiveTab(type);
                setPaymentType(paymentType);
                setTimestamp(timestamp);
                setEmployeeID(employeeID);
                setEmployeeName(employeeName);
                setProcessorTransactionReference(processorTransactionReference);
                setShipmentDetail(shipmentDetail);
                setShipmentID(shipmentID);

                setSenderBank(senderBank);
                setSenderAccountNumber(senderAccountNumber);
                setRecipientBank(recipientBank);
                setRecipientAccountNumber(recipientAccountNumber);

                setPhoneNumber(phoneNumber);
                setNetwork(network);

                const statusClass = status.toString().replaceAll(" ", "");
                setStatusClass(statusClass);

                setPaymentType(paymentType);
            });
    }, []);

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                {isError === true ? (
                    <ErrorNotification message={errorMessage}>
                        This is a notification
                    </ErrorNotification>
                ) : null}

                {isSuccess === true ? (
                    <SuccessNotification message={successMessage}>
                        This is a notification
                    </SuccessNotification>
                ) : null}
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5 className="header">Transaction Detail - {transactionID}</h5>
                        <span>{timestamp}</span>
                    </div>

                    <div className="d-flex flex-row mx-3 justify-content-between">
                        <div className={`status ${statusClass}`}>
                            {statusClass}
                        </div>
                        <div>
                            <button className="confirm-button">VIEW RECEIPT</button>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-xl-12">
                                <span className="header">Transaction Information</span>
                                <div className="card bg-light border-0 shadow">
                                    <div className="card-body shipper-container">
                                        <div className="row">
                                            <div className="col-xl-3">
                                                <span className="title">Amount</span>
                                                <p>₦{parseFloat(amount).toLocaleString("en")}</p>
                                            </div>
                                            <div className="col-xl-3">
                                                <span className="title">Type</span>
                                                <p>{type}</p>
                                            </div>
                                            <div className="col-xl-3">
                                                <span className="title">Channel</span>
                                                <p>{channel}</p>
                                            </div>
                                            <div className="col-xl-3">
                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                    <span className="title">Transaction Reference</span>
                                                    <BoxIcons.BiCopy style={{cursor:"pointer"}}/>
                                                </div>
                                                {processorTransactionReference}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={`${activeTab === "Transfer" ? "show-tab" : "hide-tab"}`}>
                                                <div className="col-xl-3">
                                                    <span className="title">Recipient Account Name</span>
                                                    <p>{accountName}</p>
                                                </div>
                                                <div className="col-xl-3">
                                                    <span className="title">Recipient Bank</span>
                                                    <p>{bank}</p>
                                                </div>
                                                <div className="col-xl-3">
                                                    <span className="title">Recipient Account Number</span>
                                                    <p>{accountNumber}</p>
                                                </div>
                                                <div className="col-xl-3">
                                                    <span className="title">Payment Type</span>
                                                    <p className={`payment-status ${paymentType}`}>{paymentType + " Transaction"}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`row mt-2 ${activeTab === "Shipment Payment" ? "show-tab" : "hide-tab"}`}>
                                            <div className="col-xl-3">
                                                <div className="d-flex flex-row align-items-baseline">
                                                    <span className="title">Shipment</span>
                                                    <span onClick={(event => goToShipment(shipmentID))} style={{fontSize:11, cursor:"pointer", color:"#0095ff", marginLeft:20}}>View Shipment</span>
                                                </div>
                                                <p style={{}}>{shipmentDetail}</p>
                                            </div>
                                            <div className="col-xl-3">
                                                <span className="title">Recipient Bank</span>
                                                <p>{recipientBank}</p>
                                            </div>
                                            <div className="col-xl-3">
                                                <span className="title">Recipient Account Number</span>
                                                <p>{recipientAccountNumber}</p>
                                            </div>
                                            <div className="col-xl-3">
                                                <span className="title">Payment Type</span>
                                                <p className={`payment-status ${paymentType}`}>{paymentType + " Transaction"}</p>
                                            </div>
                                        </div>
                                        <div className={`row mt-2 ${activeTab === "Airtime" ? "show-tab" : "hide-tab"}`}>
                                            <div className="col-xl-4">
                                                <div className="d-flex flex-row align-items-baseline">
                                                    <span className="title">Phone Number</span>
                                                </div>
                                                <p style={{}}>{phoneNumber}</p>
                                            </div>
                                            <div className="col-xl-4">
                                                <span className="title">Network</span>
                                                <p>{network}</p>
                                            </div>
                                            <div className="col-xl-4">
                                                <span className="title">Payment Type</span>
                                                <p className={`payment-status ${paymentType}`}>{paymentType + " Transaction"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-xl-6 col-md-12">
                                <span className="header">Sender Details</span>
                                <div className="card bg-light border-0 shadow">
                                    <div className="card-body shipper-container">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className="title">Name</span>
                                                {
                                                    employeeID === "0" ?
                                                        <p>{accountName}</p> :
                                                        <p onClick={(e) => goToSender(employeeID)}>{employeeName}</p>
                                                }
                                            </div>
                                            <div>
                                                <span className="title">Sender Type</span>
                                                <p>{employeeID === "0" ? "User" : "Admin"}</p>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <span className="title">Sender Bank</span>
                                            <p>{senderBank}</p>
                                        </div>
                                        <div className="mt-4">
                                            <span className="title">Sender Account</span>
                                            <p>{senderAccountNumber}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleTransaction;
