import axios from "axios";
import {UserProfileData} from "./constants";
import {SessionManagement} from "./SessionManagement";

export const URLConnection = function(url, params, callback){
    axios
        .post(url, params, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            if (response["data"]["message"] ===
                "We recently updated our app. Please logout and sign in to effect the update") {
                SessionManagement.setName("");
                SessionManagement.setEmail("");
                SessionManagement.setCompanyID("");
                SessionManagement.setCompanyName("");
                SessionManagement.setProfileImageURL("");
                SessionManagement.setUserID("");
                SessionManagement.setSessionID("");

                SessionManagement.setAuthenticationStatus("no");

                window.location.href = "/login";
            } else {
                callback(response);
            }
        });
}