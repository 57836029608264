import { useState } from "react";
import { SubMenu } from "react-pro-sidebar";

const SingleMenu = ({ item }) => {
  const [isOpened, setIsOpened] = useState(false);

  const openSubMenu = (e) => {
    setIsOpened(!isOpened);

    if (item.submenu) e.preventDefault();
  };

  return (
    <>
      <a href={`${item.submenu ? "" : item.path}`} onClick={openSubMenu}>
        <li className="menu-item">
          <div className="d-flex justify-content-center align-items-center">
            {item.icon}
            {item.title}
          </div>
          {item.submenu
            ? item.isOpened
              ? item.iconClosed
              : item.iconClosed
            : null}
        </li>
      </a>
      <ul>
        {isOpened &&
          item.submenu &&
          item.submenu.map((item, index) => {
            return (
              <a key={item.title} href={item.path}>
                <li className="submenu-item">{item.title}</li>
              </a>
            );
          })}
      </ul>
    </>
  );
};

export default SingleMenu;
