import * as BoxIcons from "react-icons/bi";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import * as TbIcons from "react-icons/tb";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiLayout className="icon" />,
  },
  {
    title: "Whatsapp",
    path: "/whatsapp",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BsIcons.BsWhatsapp className="icon" />,
  },
  {
    title: "Shipments",
    path: "/shipments",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
    iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
    icon: <BoxIcons.BiPackage className="icon" />,
    submenu: [
      {
        title: "Create Shipment",
        path: "/create-shipment",
      },
      {
        title: "All Shipments",
        path: "/shipments/all-shipments",
      },
      {
        title: "Pending Approval",
        path: "/shipments/pending-approval",
      },
      {
        title: "Pending Pickup",
        path: "/shipments/pending-pickup",
      },
      {
        title: "Picked Up",
        path: "/shipments/picked-up",
      },
      {
        title: "Pending Delivery",
        path: "/shipments/pending-delivery",
      },
      {
        title: "Delivered",
        path: "/shipments/delivered",
      },
      {
        title: "Rejected",
        path: "/shipments/rejected",
      },
      {
        title: "Cancelled",
        path: "/shipments/cancelled",
      },
    ],
  },
  {
    title: "Transactions",
    path: "/transactions",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiCoinStack className="icon" />,
  },
  {
    title: "Customers",
    path: "/customers",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiUser className="icon" />,
  },
  {
    title: "Customers Wallet",
    path: "/customers-wallet",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BsIcons.BsWallet2 className="icon" />,
  },
  {
    title: "Cashbacks",
    path: "/all-cashbacks",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown className="chevron-arrow" />,
    iconOpened: <BoxIcons.BiChevronUp className="chevron-arrow" />,
    icon: <BoxIcons.BiMoney className="icon" />,
    submenu: [
      {
        title: "All Cashbacks",
        path: "/all-cashbacks",
      },
      {
        title: "Eligible Customers",
        path: "/all-cashbacks/eligible-customers",
      },
    ]
  },
  {
    title: "Admins",
    path: "/admins",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiUserCircle className="icon" />,
  },
  {
    title: "Reports",
    path: "/reports",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiUserCircle className="icon" />,
  },
  {
    title: "Settings",
    path: "/settings",
    isOpened: false,
    iconClosed: <BoxIcons.BiChevronDown />,
    iconOpened: <BoxIcons.BiChevronUp />,
    icon: <BoxIcons.BiCog className="icon" />,
  },
];
