import {Col, Modal, Row} from "react-bootstrap";
import {createRef, useRef, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {topupUserWalletURL} from "../library/URLs";
import {UserProfileData} from "../library/constants";
import {BiX} from "react-icons/bi";
import * as React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const SuccessMessageContainer = styled.div`
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid;
    margin-bottom:20px;
    text-align:center;
`;

const LoadingContainer = styled.div`
  height: 365px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const SendEmailDialog = ({ isModalVisible, handleCloseDialog, customerID, phoneNumber, customerName }) => {
    const form = useRef(null);
    const urlPromoRef = createRef();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [message, setMessage] = useState("");

    const sendMail = () => {
        const formValues = {};
        formValues['customer_id'] = customerID;

        const data = new FormData(form.current);

        for (const [key, value] of data.entries()) {
            formValues[key] = value;
        }

        const newData = { ...formValues, ...UserProfileData() };

        // setErrorVisibility(false);
        // setIsSubmitting(true);

        console.log(message);

        // axios
        //     .post(topupUserWalletURL, newData, {
        //         // headers: {
        //         //     "Content-Type": "multipart/form-data",
        //         // },
        //         method: "POST"
        //     })
        //     .then(function (response) {
        //         console.log(response);
        //         setIsSubmitting(false);
        //
        //         if (response["data"]["success"] === false) {
        //             window.scrollTo(0, 0);
        //             setErrorVisibility(true);
        //             setErrorMessage(response["data"]["message"]);
        //
        //             setTimeout(() => {
        //                 setErrorVisibility(false);
        //             }, 5000);
        //         } else if (response["data"]["success"] === true) {
        //             setSuccessVisibility(true);
        //             setSuccessMessage("User has successfully been set to a contract user");
        //
        //             setTimeout(() => {
        //                 setSuccessVisibility(false);
        //                 handleCloseDialog();
        //             }, 3000);
        //         }
        //     })
        //     .catch(function (error) {
        //         setIsSubmitting(false);
        //         return error;
        //     });
    }

    const onChangeImage = () => {

    }

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">SEND EMAIL</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>
          {
              isError === false ? null :
                  (<ErrorMessageContainer>
                      {errorMessage}
                  </ErrorMessageContainer>)
          }

          {
              isSuccess=== false ? null :
                  (<SuccessMessageContainer>
                      {successMessage}
                  </SuccessMessageContainer>)
          }

          <form ref={form} onSubmit={sendMail}>
              <div>
                  <Row>
                      <Col>
                          <label htmlFor="User Name">To</label>
                          <input name="to" className="form-control" type="text"  />
                      </Col>
                  </Row>

                  <div className="mt-2">
                      <label htmlFor="Subject">Subject</label>
                      <input name="subject" className="form-control" type="text" />
                  </div>

                  <div className="mt-2">
                      <label htmlFor="Amount">Message</label>
                      <CKEditor
                          editor={ ClassicEditor }
                          data=""
                          onReady={ editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log( 'Editor is ready to use!', editor );
                          } }
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setMessage(data);
                              // console.log( { event, editor, data } );
                          } }
                          onBlur={ ( event, editor ) => {
                              console.log( 'Blur.', editor );
                          } }
                          onFocus={ ( event, editor ) => {
                              console.log( 'Focus.', editor );
                          } }
                      />
                  </div>
              </div>
          </form>

      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
          <SubmitButton onClick={sendMail} type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">SEND EMAIL</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default SendEmailDialog;
