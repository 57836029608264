import { Modal } from "react-bootstrap";
import styled from "styled-components";
import {BiX} from "react-icons/bi";

const SubmitButton = styled.button`
  width: 250px;
`;

const UploadRateDocumentDialog = ({ isModalVisible, handleCloseDialog, submit, isSubmitting }) => {

    const handleSubmit = async (e) => {
        e.preventDefault();

        await submit();
        handleCloseDialog();
    };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">UPLOAD RATE DOCUMENT</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>

          <p>You are about to upload rate document</p>
          <p>This means that you have vetted the document and confirmed it's for the respective carriers.</p>
          <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={(e) => handleCloseDialog(false)}>
          CANCEL
        </button>
          <SubmitButton onClick={handleSubmit} type="submit" className="confirm-button">
              {isSubmitting === false ? (
                  <span className="button-text">PROCEED</span>
              ) : (
                  <span className="spinner-border spinner-grow-sm"/>
              )}
          </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadRateDocumentDialog;
